import React, { useEffect, useState } from "react";
import solo from "../assets/solo.png";
const placeholder="/img-placeholder.jpeg"

const Singlebox = ({ image, id, type, name, handleClick, isChecked }) => {

  const [loading, setLoading] = useState(true)

  return (


    <div className="image-border">
      <label className="container" >
        {/* <input type="checkbox" className="checkmark" onChange={() => handlenftchange(id)} selected={selectedNft.includes(id)} /> */}
        <input
          id={id}
          name={name}
          type={type}
          onChange={handleClick}
          checked={isChecked}
        />
        <span className="checkmark"></span>
      </label>
      <img src={image || placeholder} onLoad={() => setLoading(false)} style={{ display: loading ? "none" : "block" }} alt="nftimage" />
      {loading ? <img src={placeholder} alt="placeholder" /> : ""}
      <div className="image-overlay">
        <div className="nft-image-overlay-hover-text">{name}</div>
      </div>
    </div>

  );
};



export const Token = ({ val, fhandleClick, isCheck,setTokenAmounts, hideAmountSendBlock, key }) => {

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");

  const handleChange = (data) => {
    setTokenAmounts((token)=>token.set(val.mint,+data))
    setValue(+data)
    console.log("token recieve amount");
  }

  const handleError = (e) => {
    e.src = placeholder
  }

  return <div
    className={`wrapper-token ${isCheck.includes(val.mint)
      ? "activeTokenBtn"
      : ""
      }`}
    key={key}
  >
    <div className="">
      <img style={{ display: loading ? "none" : "block" }}
        onLoad={() => { setLoading(false); }}
        onError={handleError}
        className="w-[60px] h-[60px] object-center object-cover rounded-lg"
        src={val.icon || placeholder}
        alt="icon"
      />
      {loading ? <img src={placeholder}
        className="w-[60px] h-[60px] object-center object-cover rounded-lg"
        alt="placeholder" /> : ""}
    </div>
    <div
      className="wrapper-token-flex-1"
      onClick={(e) => fhandleClick(val.mint)}
    >
      <div className="token-text-lg">
        {val.tokenname}
        {/* <input type="checkbox" onChange={handleClick} id={val.mint} checked={isCheck.includes(val.mint)} /> */}
      </div>
      <div>
        <p className="token-text-xs">In Wallet</p>
        <strong className="md:text-lg text-orange-400">
          {Math.round(val.amount)}
        </strong>
      </div>
    </div>
    {isCheck.includes(val.mint) ? (
      <div
        className={`wrapper-content-text-center ${hideAmountSendBlock}`}
      >
        <p className="token-text-xs">
          Amount to Send
        </p>
        <div className="wrapper-content-input-div">
          <input
            id="amount"
            value={value}
            type="number"
            className="wrapper-content-input"
            placeholder="Qty"
            onChange={e => handleChange(e.target.value)}
          ></input>
          <button className="max-btn" onClick={() => handleChange(Math.floor(val.amount))}>Max</button>
        </div>
        {/* <Amount tokenssendamounts={tokenssendamounts} val={val} setamount={setamount} /> */}
      </div>
    ) : (
      <div></div>
    )}
  </div>
}

export default Singlebox;