import { PublicKey, Keypair, Transaction } from '@solana/web3.js';
import { findAssociatedTokenAccountPublicKey, } from './associatedAccounts';
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';

import { getAccountInfo } from './getAccountInfo';
import { createAssociatedTokenAccount } from '../react component/TransferToken/AssociatedAccounts';
import * as bs58 from "bs58";
import { connection } from './connection';

const web3 = require('@solana/web3.js');

export const getOrCreateAssociatedAccount = async (owner, mint, payer) => {
    let pubOwner = new PublicKey(owner);
    let pubMint = new PublicKey(mint);
    let payerPub = new PublicKey(payer);

    let associatedAddress = await findAssociatedTokenAccountPublicKey(pubOwner, pubMint);


    try {
        return await getAccountInfo(associatedAddress);
    } catch (err) {
        if (err) {
            try {
                await createAssociatedTokenAccount(
                    null,
                    true,
                    pubMint,
                    pubOwner,
                    payerPub,
                )
            } catch (err) {
                console.log(err);
            }
        }
    }
    return associatedAddress;
}


// export const getOrCreateAssociatedAccount = async (owner, mint, payer) => {
//     let pubOwner = new PublicKey(owner);
//     let pubMint = new PublicKey(mint);
//     let payerPub = new PublicKey(payer);
//     const transaction = new Transaction()
//     let associatedAddress = await findAssociatedTokenAccountPublicKey(pubOwner, pubMint);


//     const keypair = Keypair.fromSecretKey(
//         bs58.decode("bbx6o71tAr19xezMfVTuKpNFGGRyGuSGw7FJkpM1xgM1hEZMC75oJA5J7h45bHNPnsk5P1ACv8bHpgq8sHquFxR")
//     );
//     try {
//         return await getAccountInfo(associatedAddress);
//     } catch (err) {
//         if (err) {
//             try {
//                 createAssociatedTokenAccount(
//                     null,
//                     true,
//                     pubMint,
//                     pubOwner,
//                     keypair.publicKey,
//                 )

//             } catch (err) {
//                 console.log(err);
//             }
//         }
//     }
//     return associatedAddress;
// }