import {
    Token,
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID,
  } from '@solana/spl-token';
  import {
    PublicKey,
    LAMPORTS_PER_SOL,
    SystemProgram,
    Transaction,
    Keypair,
  } from '@solana/web3.js';
  import { findAssociatedTokenAccountPublicKey } from './associatedAccounts';
  import { connection } from './connection';
  import { sendTxUsingExternalSignature } from './externalWallet';
  import { getOrCreateAssociatedAccount } from './getOrCreateAssociatedAccount';
  import { createAssociatedTokenAccount } from '../react component/TransferToken/AssociatedAccounts';
  import { getAccountInfo } from './getAccountInfo';
  import { ToastContainer, toast } from 'react-toastify';
  
  import * as bs58 from 'bs58';
  import Alert from '@mui/material/Alert';
  import { Nftquery } from './Nftquery';
  
  export const bundletransferTokenHandler = async (
    mintaddresslist,
    recieveraddresslist,
    amountlist,
    ownerpubKey,
    feereceiver
  ) => {
    const showToastMessage = (e) => {
      toast.error('Transactions Failed :  ' + e, {
        position: toast.POSITION.BOTTOM_LEFT,
        className: 'toast-message',
      });
    };
    const showsucessToastMessage = () => {
      toast.success('Transactions Sucessful', {
        position: toast.POSITION.BOTTOM_LEFT,
        className: 'toast-message',
      });
    };
    const transaction = new Transaction();
  
    const ownerPub = new PublicKey(ownerpubKey);
    let lamportsI =
      mintaddresslist.length *
      LAMPORTS_PER_SOL *
      process.env.REACT_APP_FEE_AMOUNT; //service fee
  
    const nft = JSON.parse(localStorage.getItem('nft'));
  
    for (let i = 0; i < nft.length; i++) {
      
      if (nft[i].collectionAddress === process.env.REACT_APP_NFT_COLLECTION_ID) {
        for (let j = 0; j < mintaddresslist.length; j++) {
          
          if (mintaddresslist[j] === nft[i].tokenAddress) {
            lamportsI =
              lamportsI - LAMPORTS_PER_SOL * process.env.REACT_APP_FEE_AMOUNT;
          }
        }
      }
    }
  
    for (let i = 0; i < nft.length; i++) {
      
      if (nft[i].collectionAddress === process.env.REACT_APP_NFT_COLLECTION_ID) {
        lamportsI = 0;
      }
    }
  
    if (lamportsI) {
      transaction.add(
        SystemProgram.transfer({
          fromPubkey: ownerPub,
          toPubkey: new PublicKey(feereceiver),
          lamports: lamportsI,
        })
      );
    }
  
    for (let i = 0; i < mintaddresslist.length; i++) {
      let tokenPub = new PublicKey(mintaddresslist[i]);
      let destPub = new PublicKey(recieveraddresslist[i]);
  
      // let tokenAssociatedAddress = await getOrCreateAssociatedAccount(
      //     destPub,
      //     tokenPub,
      //     ownerPub.toString()
      // )
      let associatedAddress = await findAssociatedTokenAccountPublicKey(
        destPub,
        tokenPub
      );
  
      try {
        await getAccountInfo(associatedAddress);
      } catch (err) {
        if (err) {
          let kk = await createAssociatedTokenAccount(
            null,
            true,
            tokenPub,
            destPub,
            ownerPub
          );
          transaction.add(kk);
        }
      }
  
      //ASSUMING THAT BOTH OWNER AND DESTINATION HAS AN ACCOUNT ASSOCIATED
      //Finding Associated Account of owner
      let assOwnerAccount = await findAssociatedTokenAccountPublicKey(
        ownerPub,
        tokenPub
      );
      console.log('Owner Associated Account: ', assOwnerAccount.toString());
      // //Finding the Asscociated Account of destination
      let assDestAccount = await findAssociatedTokenAccountPublicKey(
        destPub,
        tokenPub
      );
      console.log('Destination Associated Account: ', assDestAccount.toString());
      // if (tokenAssociatedAddress) tokenAssociatedAddress !== assDestAccount && console.log(false);
      // const transaction = new Transaction();
      let ix = Token.createTransferInstruction(
        TOKEN_PROGRAM_ID, //PROGRAM_ID
        assOwnerAccount, //Associated Owner Account
        assDestAccount, //Associated Destination Account
        ownerPub, //Owner
        [], //multisigners
        amountlist[i] //Amount
      );
      transaction.add(ix);
  
      if (transaction.instructions.length > 11) {
        // Assuming that the source and the feepayer are the same
        await sendTxUsingExternalSignature(
          [transaction],
          connection,
          null,
          [],
          ownerPub
        )
          .then((res) => {
            console.log(res);
            // alert("Transaction Successful");
          })
          .catch((err) => {
            console.log(err);
          });
        while (transaction.instructions.length > 0) {
          transaction.instructions.pop();
        } //
      }
    }
  
    await sendTxUsingExternalSignature(
      [transaction],
      connection,
      null,
      [],
      ownerPub
    )
      .then(async (res) => {
        console.log(res);
        showsucessToastMessage();
        // alert("Transaction Successful");
        return 'success';
      })
      .catch((err) => {
        console.log(err.message);
        showToastMessage(err.message);
        // alert(err);
        return 'fail';
      });
  };
  