import React, { useEffect, useState, useCallback, useRef } from 'react';
// import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import soloKillerBunnies from '../assets/solo-killer-bunnies.webp';
// import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from '@mui/material/Input';
import WebFont from 'webfontloader';
import NFTJSONDATA from '../assets/nft.json';
import TOKENJSONDATA from '../assets/token.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getNFTs from '../Display.js';
import phantom_icon from '../assets/Phantom_icon.svg';
import { getTokenAccounts } from '../Components/tokenquery';
import {
  Connection,
  GetProgramAccountsFilter,
  LAMPORTS_PER_SOL,
  clusterApiUrl,
} from '@solana/web3.js';
import TEMPLATEJSONDATA from '../assets/template.json';
import { bundletransferTokenHandler } from '../Components/bundletransfer';
import { bundleTokentransferFeeCut } from '../Components/bundletokentransfer';
import Checkbox from './Checkbox';
import axios from 'axios';
import { render } from '@testing-library/react';
import Single from './Single';
import Amount from './Amount';
import { margin } from '@mui/system';
import solo from '../assets/solo.png';
import skb180xwh from '../assets/skb180xwh.png';
import { Token } from './Singlebox';
import { Loader } from '../react component/Loader/loader';
import { useInfiniteQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';

var topBannerSectionStyle = {
  width: '100%',
  minHeight: '48vh',
  backgroundImage:
    "linear-gradient(rgb(46 0 75 / 0%), #212121),url('bgImage.jpeg')",
};
const style1 = {
  position: 'fixed',
  bottom: '0%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 600,
  // bgcolor: 'rgba(17, 24, 39, 0.8)',
  // boxShadow: 24,
  // p: 4,
};

const style = {
  position: 'fixed',
  bottom: '0%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: '30%',
  // bgcolor: 'rgba(17, 24, 39, 0.8)',
  // boxShadow: 24,
  // p: 4,
  background: 'rgba(17, 24, 39, .8)',
  margin: '7px',
  padding: '10px',
  marginBottom: '15px',
  borderRadius: '15px',
};

export default function Nft() {
  var tokensselected = new Map();
  var tokenssendamounts = new Map();
  const [tokenAmounts, setTokenAmounts] = useState(tokenssendamounts);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [checked, setChecked] = useState(false);
  const [selectedCount, setSelectedCount] = useState(null);
  const [subMenuActive, setSubMenuActive] = useState(null);
  const [disconnectActive, setDisconnectActive] = useState(null);
  const [hideAmountSendBlock, setHideAmountSendBlock] = useState(null);
  const [connectHide, setConnectHide] = useState(null);
  const [connectWalletIdBtn, setConnectWalletIdBtn] = useState(null);
  const [connectWalletBtn, setConnectWalletBtn] = useState(null);
  const [confirmSubmitBtn, setConfirmSubmitBtn] = useState(null);
  const [confirmLoaderBtn, setConfirmLoaderBtn] = useState(null);
  const [tokenBlock, setTokenBlock] = useState(null);
  const [nftBlock, setNftBlock] = useState(null);
  const [open, setOpen] = React.useState(true);
  const [openToken, setOpenToken] = React.useState(false);
  const [openNoNft, setOpenNoNft] = React.useState(false);
  const [openNoToken, setOpenNoToken] = React.useState(false);
  const [nftBtn, setNftBtn] = React.useState(false);
  const [activeToken, setActiveToken] = React.useState(false);
  const [tokenBtn, setTokenBtn] = React.useState(true);
  const [inputHandleValue, setInputHandleValue] = React.useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [menuToogle, setMenuToogle] = React.useState(false);
  const [pubKey, setPubKey] = useState('');
  const [loadnft, setLoadnft] = useState('loading-nfts-hide');
  const [connectbutton, setconnectbutton] = useState('Connect Wallet');
  const [nfts, setNfts] = useState([]);
  const [megalist, setMegalist] = useState([]);
  const [tokenlist, setTokenlist] = useState([]);
  const [selectedNft, setSelectedNft] = React.useState([]);
  const [nftreciverlist, setNftreciverlist] = useState('');
  const [ownednft, setOwnednft] = useState('own-nfts');
  const [ownedtoken, setOwnedtoken] = useState('own-tokens');
  const [nftsendto, setNftsendto] = useState('sendtoone');
  const [confirmbtn, setConfirmbtn] = useState('Confirm');
  const [nftselectcount, setNftselectcount] = useState([]);
  const [tokensendto, setTokensendto] = useState('sendtoone');
  // const [tokensendamount, setTokensendamount] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const [loadtoken, setLoadtoken] = useState('load-token-hide');
  const [tokenreciver, setTokenreciver] = useState('');
  const [processtranscution, setProcesstranscution] = useState(true);
  const [showContent, setShowContent] = useState(false);

  
  const [isCollectionAddress, setIsCollectionAddress] = useState(false);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(tokenlist.map((li) => li.mint));
  };
  const handleRemoveAll = (e) => {
    setIsCheck([]);
    setIsCheckAll(false);
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
    console.log(isCheck);
  };
  const fhandleClick = (id) => {
    let checked = isCheck.includes(id);
    setIsCheck([...isCheck, id]);
    if (checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
    console.log(isCheck);
    console.log('tokenamount');
    console.log(tokenAmounts);
  };

  console.log(isCheck);

  const dynamicCSS = `	
    .btnTransparent{	
        background: ${TEMPLATEJSONDATA.btnTransparent}	
    }	
    .btnOrange{	
        background: ${TEMPLATEJSONDATA.btnOrange}	
    }	
    .btnBlue{	
        background: ${TEMPLATEJSONDATA.btnRed}	
    }	
    .btnPerpule{	
        background: ${TEMPLATEJSONDATA.btnPerpule}	
    }   	
    .btnDarkGray{	
        background: ${TEMPLATEJSONDATA.btnDarkGray}	
    }   	
    .bannerColor{	
       background-image: ${TEMPLATEJSONDATA.bannerColor}; 	
    }	
        .btnBlueOpacity{	
        background-color: ${TEMPLATEJSONDATA.btnRed}	
    }	
    .horizontalLine{	
        background-color: ${TEMPLATEJSONDATA.horizontalLine}	
    }
    `;

  const handleSearchInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'search') {
      setSearchInput(value);
    }
  };

  const handleInputChange = (e) => {
    console.log(e.target, 'bhbj');
  };
  const connectWallet = async () => {
    const provider = window.solana;
    console.log(provider);
    if (provider) {
      //setCount(count + 1);
      await window.solana.connect();
      // window.solana.on("connect", () => console.log("connect"));
      getConnectedWallet();
    } else window.open('https://phantom.app/', '_blank');
  };
  const getConnectedWallet = async () => {
    const provider = await window.solana;
    if (provider) {
      setPubKey(provider.publicKey);
      if (provider.publicKey) {
        setconnectbutton(provider.publicKey.toString().slice(0, 9));
        setNftBlock('nft-block-active');
        setTokenBlock('token-block-hide');
        setTokenBtn(true);
        setNftBtn(false);
        getNFTsNewApi();
      }
      // console.log("Is Phantom installed?  ", provider.isPhantom);
      //return provider;
    } else console.log('Try to connect again');
  };
  const showToastMessage = () => {
    toast.error('Error signing transactions', {
      position: toast.POSITION.BOTTOM_LEFT,
      className: 'toast-message',
    });
  };
  const showsucessToastMessage = () => {
    toast.success(' transactions sucessful', {
      position: toast.POSITION.BOTTOM_LEFT,
      className: 'toast-message',
    });
  };

  const showErrorMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      className: 'toast-message',
    });
  };
  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      className: 'toast-message',
    });
  };

  async function changewallet(e) {
    if ((e.type = 'click')) {
      disconnectFunction();
      await new Promise((resolve) => setTimeout(resolve, 1000)); // 3 sec
      connectWalletBtnFunction();
    }
  }

  React.useEffect(() => {
    if (pubKey) {
      getNFTsNewApi();
    }
  }, [pubKey]);

  React.useEffect(() => {
    setConnectHide('connect-hide');
    setDisconnectActive('disconnect-active');
    setSubMenuActive('sub-menu');
    setConfirmSubmitBtn('confirm-btn-active');
    setConfirmLoaderBtn('confirm-loader-btn-hide');
    setConnectWalletIdBtn('connect-wallet-id-btn-hide');
    setConnectWalletBtn('connect-wallet-btn-active');
    setTokenBlock('token-block-hide');
    setInputHandleValue('1');
  }, []);
  function maxValueFunction(e) {
    // console.log(e.target, 'e.target')
    if ((e.type = 'click')) {
      setMaxValue('10');
      setInputHandleValue(maxValue);
    }
  }
  function connectWalletBtnFunction() {
    setConnectWalletIdBtn('connect-wallet-id-btn-active');
    setConnectWalletBtn('connect-wallet-btn-hide');
    setConnectHide('connect-active');
    setDisconnectActive('disconnect-hide');
    connectWallet();
  }

  function confirmSubmitFunction(e) {
    // e.preventDefault();
    if ((e.type = 'click')) {
      setConfirmSubmitBtn('confirm-btn-hide');
      setConfirmLoaderBtn('confirm-loader-btn-active');
      showToastMessage();
    }
  }
  function tokenBlockFunction(e) {
    if ((e.type = 'click')) {
      setOwnedtoken('own-tokens');
      setTokenBlock('token-block-active');
      setNftBlock('nft-block-hide');
      // setTokenBtn('tokenBtn')
      setTokenBtn(false);
      setNftBtn(true);
      gettokenfuncation();
      setOwnednft('own-nfts');
    }
  }
  function nftBlockFunction(e) {
    if ((e.type = 'click')) {
      setOwnednft('own-nfts');
      setNftBlock('nft-block-active');
      setTokenBlock('token-block-hide');
      setTokenBtn(true);
      setNftBtn(false);
      // getNFTs();
      getNFTsNewApi();
      setOwnedtoken('own-tokens');
    }
  }
  async function getNFTs({ pageParam = 1 }) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const data = {
      jsonrpc: '2.0',
      id: 1,
      method: 'qn_fetchNFTs',
      params: {
        
        wallet: pubKey, //pubKey.toString(),                   //"DcTmx4VLcf5euAB17nynax7g55xuB3XKBDyz1pudMcjW",                                                               //pubKey.toString(),
        omitFields: ['provenance', 'traits'],
        page: pageParam,
        perPage: 41,
      },
    };
    return (
      axios
        //hrithik edit :changed the url to my endpoint
        .post(process.env.REACT_APP_RCP_END_POINT, data, config)
        .then(function (response) {
          console.log('Response', response);
          // handle success
          return response.data.result;
          
          // let fetchnfts = response.data.result.assets;
          // let nftlist = [];
          // nftlist.push(fetchnfts[0]);
          // for (let i = 0; i < fetchnfts.length; i++) {
          //   if (
          //     fetchnfts[i].collectionAddress !== fetchnfts[0].collectionAddress
          //   ) {
          //     nftlist.push(fetchnfts[i]);
          //   }
          // }
          // let nft = [];
          // for (let k = 0; k < nftlist.length; k++) {
          //   for (let i = 0; i < fetchnfts.length; i++) {
          //     if (
          //       nftlist[k].collectionAddress === fetchnfts[i].collectionAddress
          //     ) {
          //       nft.push(fetchnfts[i]);
          //     }
          //   }
          // }
          // let meganftlist = [];
          // for (let i = 0; i < nftlist.length; i++) {
          //   let nftlist1 = [];
          //   for (let k = 0; k < nft.length; k++) {
          //     if (nftlist[i].collectionAddress === nft[k].collectionAddress) {
          //       nftlist1.push(nft[k]);
          //     }
          //   }
          //   meganftlist.push(nftlist1);
          // }
          // console.log(meganftlist);
          // localStorage.setItem('megalist', JSON.stringify(meganftlist));
          // setMegalist(meganftlist);
          // console.log(nft);
          // setNfts(nft);
          // localStorage.setItem('nft', JSON.stringify(nft));
        })
        .catch((err) => {
          // handle error
          console.log(err);
        })
    );
  }

  const transformData = (response) => {
    let nftlist = [];
    let fetchnfts = response.data.data.list_nfts;
    console.log(fetchnfts.length);
    let categaory = [];
    categaory.push(fetchnfts[0]);
    for (let i = 1; i < fetchnfts.length; i++) {
      let flag = false;
      for (let k = 0; k < categaory.length; k++) {
        if (
          
          !fetchnfts[i].collectionAddress.localeCompare(
            categaory[k].collectionAddress
          )
        ) {
          flag = true;
        }
      }
      if (!flag) {
        categaory.push(fetchnfts[i]);
      }
    }
    nftlist.push(fetchnfts[0]);

    let meganftlist = [];
    for (let i = 0; i < categaory.length; i++) {
      let nftlist1 = [];
      for (let k = 0; k < fetchnfts.length; k++) {
        
        if (categaory[i].collectionAddress === fetchnfts[k].collectionAddress) {
          nftlist1.push(fetchnfts[k]);
        }
      }
      meganftlist.push(nftlist1);
    }
    localStorage.setItem('megalist', JSON.stringify(meganftlist));
    // setMegalist(meganftlist);
    // setNfts(fetchnfts);
    localStorage.setItem('nft', JSON.stringify(fetchnfts));
    setLoadnft('loading-nfts-hide');
    if (fetchnfts.length === 0) {
      setOwnednft('own-zero-nfts');
    } else {
      setOwnednft('own-nfts');
    }

    return { meganftlist, fetchnfts };
  };



  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    
    useInfiniteQuery(['NFTS'], getNFTs, {
      enabled: Boolean(pubKey),
      getNextPageParam: (_lastPage, pages) => {
        
        console.log('pages', pages);
        const index=pages.length - 1;

        if(!pages[index])
        {
          return undefined
        }

        if(pages[index]?.totalPages>pages[index]?.pageNumber)
        {
            return pages[index].pageNumber + 1
        }
        return undefined;
      },
    });

  

  const Iconstyles = {
    icon1: {
      width: '60px',
      height: '60px',
      marginTop: '20px',
      top: '0',
      position: 'fixed',
      left: '1rem',
      zIndex: '10',
      padding: '4px',
      borderRadius: '50%',
      backgroundColor: 'black',
      cursor:'pointer',
    },
    icon2: {
      width: '60px',
      height: '60px',
      marginTop: '20px',
      top: '0',
      position: 'fixed',
      left: '1rem',
      zIndex: '10',
      padding: '4px',
      borderRadius: '50%',
      border:'2px solid #fdd835',
      backgroundColor: 'black',
      cursor:'pointer',
    },
  };

  useEffect(() => {
    console.log('data', data);
    if (data) {
      const { pages } = data;

      if (pages && pages.length) {
        var fetchnftsArr = [];

        for (let i in pages) {
          if (pages[i]) {
            console.log('pages[i]', pages[i]);
            
            fetchnftsArr = [...fetchnftsArr, ...pages[i].assets];
          }
        }

        
        const nfts_list = data.pages[0].assets;
        {
          nfts_list.map((item) => {
            if (
              item.collectionAddress === process.env.REACT_APP_NFT_COLLECTION_ID
            ) {
              setIsCollectionAddress(true);
            } 
          });
        }

        let response = {
          data: {
            data: {
              list_nfts: fetchnftsArr,
              num_nfts: pages[0]?.data?.data?.num_nfts,
              wallet_address: pages[0]?.owner,
            },
          },
        };

        let { meganftlist, fetchnfts } = transformData(response);
        setMegalist([fetchnfts]);
        setNfts(fetchnfts);
        console.log(meganftlist);
      }
    }
  }, [data?.pages.length]);

  useEffect(() => {
    if (isLoading) {
      setLoadnft('loading-nfts-active');
    } else {
      setLoadnft('loading-nfts-hide');
    }
  }, [isLoading]);

  async function getNFTsNewApi() {
    // if (pubKey) {

    if (pubKey) {
      const rpcEndpoint = process.env.REACT_APP_RCP_END_POINT; //                  'https://api.devnet.solana.com';
      const solanaConnection = new Connection(rpcEndpoint);
      let token = await getTokenAccounts(pubKey, solanaConnection); //"94gjhowSM4p2ssz8s2twaBJFmScv1cYuRftrxcwtvBwQ"
      localStorage.setItem('token', JSON.stringify(token));
    }

    //     if (nfts.length === 0) {
    //         setLoadnft("loading-nfts-active");
    //     }
    //     (() => {
    //         let tt = "https://pro-api.solscan.io/v1.0/wallet/nft/" + pubKey.toString() + "?limit=10000" //   "F9vHiTZ6vym11VVwEBWtfurACBg4yURC99V9XtqxqgrE";  pubKey.toString();

    //         axios
    //             .get(tt)
    //             .then(function (response) {
    //                 console.log(response);
    //                 // handle success
    //                 console.log(response.data.data.list_nfts);
    //                 let fetchnfts = response.data.data.list_nfts;
    //                 console.log(fetchnfts.length);
    //                 let nftlist = [];
    //                 // for (let i = 0; i < fetchnfts.length; i++) {
    //                 //     if (fetchnfts[i].nft_collection_id === "") {
    //                 //         fetchnfts[i] = fetchnfts[fetchnfts.length - 1];
    //                 //         fetchnfts.pop();

    //                 //     }
    //                 // }
    //                 console.log(fetchnfts);
    //                 console.log(fetchnfts.length);
    //                 let categaory = [];
    //                 categaory.push(fetchnfts[0]);
    //                 for (let i = 1; i < fetchnfts.length; i++) {
    //                     let flag = false;
    //                     for (let k = 0; k < categaory.length; k++) {
    //                         if (!((fetchnfts[i].nft_collection_id).localeCompare(categaory[k].nft_collection_id))) {
    //                             flag = true;
    //                         }
    //                     }
    //                     if (!flag) {
    //                         categaory.push(fetchnfts[i]);
    //                     }
    //                 }
    //                 console.log(categaory);
    //                 nftlist.push(fetchnfts[0]);

    //                 let meganftlist = [];
    //                 for (let i = 0; i < categaory.length; i++) {
    //                     let nftlist1 = [];
    //                     for (let k = 0; k < fetchnfts.length; k++) {
    //                         if ((categaory[i].nft_collection_id) === (fetchnfts[k].nft_collection_id)) {
    //                             nftlist1.push(fetchnfts[k]);
    //                         }
    //                     }
    //                     meganftlist.push(nftlist1);
    //                 }
    //                 console.log(meganftlist);
    //                 localStorage.setItem("megalist", JSON.stringify(meganftlist));
    //                 setMegalist(meganftlist);
    //                 setNfts(fetchnfts);
    //                 localStorage.setItem("nft", JSON.stringify(fetchnfts));
    //                 setLoadnft("loading-nfts-hide");
    //                 if (fetchnfts.length === 0) {
    //                     setOwnednft("own-zero-nfts");
    //                 } else {
    //                     setOwnednft("own-nfts");
    //                 }

    //             })
    //             .catch((err) => {
    //                 // handle error
    //                 console.log(err);
    //                 setLoadnft("loading-nfts-hide");
    //             });

    //     })();
    // }
  }
  function subMenuFunction(e) {
    if ((e.type = 'click')) {
      if (subMenuActive === 'sub-menu-active') {
        setSubMenuActive(false);
      } else {
        setSubMenuActive('sub-menu-active');
      }
    }
  }
  function disconnectFunction() {
    setConnectHide('connect-hide');
    setDisconnectActive('disconnect-active');
    setConnectWalletBtn('connect-wallet-btn-active');
    setConnectWalletIdBtn('connect-wallet-id-btn-hide');
    const provider = window.solana;
    setSubMenuActive(null);
    setconnectbutton('connect wallet');
    window.solana.disconnect();
  }
  function menuClickEvent() {
    setMenuToogle((current) => !current);
  }

  
  function imgClickEvent() {
    if (window.location.pathname === '/') {
      window.scrollTo(0, 0);
    } else {
      window.location.href = '/';
    }
  }
  console.log(menuToogle, '////////');
  const handleClose = () => setOpen(false);
  const handleTokenClose = () => setOpenToken(false);
  const handleNoNftClose = () => setOpenNoNft(false);
  const handleNoTokenClose = () => setOpenNoToken(false);
  // function handleChange(id) {

  //     // console.log(e.target.value)
  //     setChecked(!checked);

  // };
  function handlenftchange(id, key) {
    var selectedCheckboxes = selectedNft;
    const findIdx = selectedCheckboxes.indexOf(id);
    // console.log(this.nft)

    // Index > -1 means that the item exists and that the checkbox is checked
    // and in that case we want to remove it from the array and uncheck it
    if (findIdx > -1) {
      selectedCheckboxes.splice(findIdx, 1);
    } else {
      selectedCheckboxes.push(id);
    }

    setSelectedNft(selectedCheckboxes);
    localStorage.setItem(
      'selectedCheckboxes',
      JSON.stringify(selectedCheckboxes)
    );
    console.log(selectedCheckboxes);
    console.log(selectedNft.includes(id));
    let localcount = nftselectcount;
    let k = 0;
    let tt = megalist[key];
    for (let i = 0; i < tt.length; i++) {
      
      if (selectedNft.includes(tt[i].tokenAddress)) {
        k++;
      }
      localcount[key] = k;
    }
    setNftselectcount(localcount);
    console.log(nftselectcount);
  }
  function bulk(id) {
    let tt = megalist[id];
    for (let i = 0; i < tt.length; i++) {
      
      handlenftchange(tt[i].tokenAddress, id);
    }
  }
  // React.useEffect(() => {
  //     if (checked == true) {
  //         setSelectedCount('1')
  //         setOpen(true)
  //     } else {
  //         setSelectedCount('0')
  //         setOpenNoNft(true)
  //     }
  // }, [checked])
  function activeTokenFunction(e) {
    if ((e.type = 'click')) {
      setActiveToken((current) => !current);
    }
    console.log(activeToken, 'activeToken');
    if (activeToken == true) {
      setOpenNoToken(true);
      setHideAmountSendBlock('hide-amount-send-block');
    }
    if (activeToken == false) {
      setOpenToken(true);

      setHideAmountSendBlock('');
    }
  }
  function selectingtokenfunction(tokenmint) {
    console.log(tokenmint);
    if (tokensselected.has(tokenmint)) {
      if (tokensselected.get(tokenmint) === true) {
        tokensselected.set(tokenmint, false);
        setHideAmountSendBlock('');
      } else {
        tokensselected.set(tokenmint, true);
        setHideAmountSendBlock('hide-amount-send-block');
      }
    } else {
      tokensselected.set(tokenmint, true);
      setHideAmountSendBlock('hide-amount-send-block');
    }
    console.log(tokensselected);
  }
  async function gettokenfuncation() {
    if (tokenlist.length === 0) {
      setLoadtoken('load-token-active');
    }
    console.log('1');
    const rpcEndpoint = process.env.REACT_APP_RCP_END_POINT; //                  'https://api.devnet.solana.com';
    const solanaConnection = new Connection(rpcEndpoint);
    let token = await getTokenAccounts(pubKey, solanaConnection); //"94gjhowSM4p2ssz8s2twaBJFmScv1cYuRftrxcwtvBwQ"
    localStorage.setItem('token', JSON.stringify(token));
    setTokenlist(token);
    for (let i = 0; i < tokenlist.length; i++) {
      setTokenAmounts((data) => data.set(tokenlist[i].mintAddress, 0));
      // tokenssendamounts.set(
      //     tokenlist[i].mintAddress,
      //     0
      // )
    }
    console.log(tokenAmounts);
    setLoadtoken('load-token-hide');
    if (token.length === 0) {
      setOwnedtoken('own-zero-tokens');
    }
  }
  async function bundlenfttransfer() {
    let mintaddresslist = JSON.parse(
      localStorage.getItem('selectedCheckboxes')
    );

    if (nftsendto === 'sendtomany') {
      var str = nftreciverlist;
      var recieveraddresslist = str.split(',');
      let amountlist = [];
      for (let i = 0; i < mintaddresslist.length; i++) {
        amountlist.push(1);
      }
      if (recieveraddresslist.length == mintaddresslist.length) {
        setConfirmbtn();
        let result = await bundletransferTokenHandler(
          mintaddresslist,
          recieveraddresslist,
          amountlist,
          pubKey,
          process.env.REACT_APP_FEE_RECIEVER
        );

        setConfirmbtn('confirm');
        //                 if (result === "success") {
        //                     showsucessToastMessage();
        //                 } else {
        //                     showToastMessage();
        //                 }
      } else {
        alert('please enter recever address correctly');
      }
    } else {
      let mintaddresslist = JSON.parse(
        localStorage.getItem('selectedCheckboxes')
      );
      var str = nftreciverlist;
      let recieveraddresslist = [];
      for (let i = 0; i < mintaddresslist.length; i++) {
        recieveraddresslist.push(str);
      }
      let amountlist = [];
      for (let i = 0; i < mintaddresslist.length; i++) {
        amountlist.push(1);
      }
      if (recieveraddresslist.length == mintaddresslist.length) {
        setConfirmbtn();
        let result = await bundletransferTokenHandler(
          mintaddresslist,
          recieveraddresslist,
          amountlist,
          pubKey,
          process.env.REACT_APP_FEE_RECIEVER
        );

        setConfirmbtn('confirm');
        //                 if (result === "success") {
        //                     showsucessToastMessage();
        //                 } else {
        //                     showToastMessage();
        //                 }
      } else {
        alert('please enter recever address correctly');
      }
    }
    // }
  }
  async function bundletokentransfer() {
    if (!tokenreciver) {
      showErrorMessage('Invalid Address');
      return;
    }
    console.log('token recieve amount');
    console.log(tokenAmounts);
    setProcesstranscution(false);
    let mintaddresslist = isCheck;
    let amountlist = [];
    for (let i = 0; i < mintaddresslist.length; i++) {
      amountlist.push(tokenAmounts.get(mintaddresslist[i]));
    }
    if (tokensendto === 'sendtomany') {
      var str = tokenreciver;
      var recieveraddresslist = str.split(',');
      let lengt = recieveraddresslist.length;
      let k = lengt * mintaddresslist.length;

      // for (let i = 0; i < lengt; i++) {
      //     for (let j = 0; j < mintaddresslist.length; j++) {
      //         recieveraddresslist.push(recieveraddresslist[j]);
      //     }
      // }

      // for (let i = 0; i < lengt; i++) {
      //     for (let j = 0; j < mintaddresslist.length; j++) {
      //         mintaddresslist.push(mintaddresslist[j]);
      //     }
      // }

      for (let i = 0; i < mintaddresslist.length; i++) {
        amountlist[i] = Number(amountlist[i]) * (1000000000 / lengt);
        console.log(amountlist[i]);
      }

      try {
        for (let i = 0; i < k - lengt; i++) {
          recieveraddresslist.push(recieveraddresslist[i]);
        }
      } catch (e) {
        console.log(e);
      }

      let l = mintaddresslist.length;
      try {
        for (let i = 0; i < k - l; i++) {
          mintaddresslist.push(mintaddresslist[i]);
        }
      } catch (e) {
        console.log(e);
      }
      let f = amountlist.length;
      try {
        for (let i = 0; i < k - f; i++) {
          amountlist.push(amountlist[i]);
        }
      } catch (e) {
        console.log(e);
      }
      console.log('mintaddresslist');
      console.log(mintaddresslist);
      console.log(recieveraddresslist);
      console.log(amountlist);
      if (
        recieveraddresslist.length == mintaddresslist.length &&
        amountlist.length == mintaddresslist.length
      ) {
        setConfirmbtn();
        let result = await bundleTokentransferFeeCut(
          mintaddresslist,
          recieveraddresslist,
          amountlist,
          pubKey,
          process.env.REACT_APP_FEE_RECIEVER,
          isCollectionAddress
        );

        setConfirmbtn('confirm');
        // gettokenfuncation();
        // if (result === "success") {
        //     showsucessToastMessage();
        // } else {
        //     showToastMessage();
        // }
        setIsCheck([]);
        setIsCheckAll(false);
        setTokenreciver('');
      } else {
        alert('please enter details correctly');
      }
    } else {
      let recieveraddresslist = [];
      for (let i = 0; i < mintaddresslist.length; i++) {
        recieveraddresslist.push(tokenreciver);
      }
      for (let i = 0; i < mintaddresslist.length; i++) {
        amountlist[i] = Number(amountlist[i]) * 1000000000;
        console.log(amountlist[i]);
      }
      if (
        recieveraddresslist.length == mintaddresslist.length &&
        amountlist.length == mintaddresslist.length
      ) {
        setConfirmbtn('');

        let result = await bundleTokentransferFeeCut(
          mintaddresslist,
          recieveraddresslist,
          amountlist,
          pubKey,
          process.env.REACT_APP_FEE_RECIEVER,
          isCollectionAddress
        );
        setConfirmbtn('confirm');
        gettokenfuncation();
        // if (result === "success") {
        //     showsucessToastMessage();
        // } else {
        //     showToastMessage();
        // }
        setIsCheck([]);
        setIsCheckAll(false);
        setTokenreciver('');
      } else {
        alert('please enter details correctly');
      }
    }
    setProcesstranscution(true);
  }

  function displaynftconfirm(nftsend) {
    console.log(nftsend);
    setNftsendto(nftsend);
    var selectedCheckboxes = selectedNft;

    if (selectedCheckboxes.length > 0) {
      setOpen(true);
    }
  }

  function displaytokenconfirm(tokensend) {
    setTokensendto(tokensend);
  }
  const setamount = (e, d) => {
    setTokenAmounts((data) => data.set(e, d));

    console.log(tokenAmounts);
  };

  const dropdownRef = useRef(null);

  const closeDropdown = (e) => {
    if (
      dropdownRef.current &&
      subMenuActive &&
      !dropdownRef.current.contains(e.target)
    ) {
      setSubMenuActive(null);
    }
  };

  window.addEventListener('mousedown', closeDropdown);

  return (
    <div>
      <style>{dynamicCSS}</style>
      <div className='main-wrapper'>
        <div className='top-banner bannerColor' style={topBannerSectionStyle}>
          <div className='menuiconDiv' onClick={menuClickEvent}>
            <label className='menuiconlabel'>
              <span> &#9776;</span>
            </label>
          </div>
          
          <img
            src={skb180xwh}
            className='icon'
            alt='logo'
            onClick={imgClickEvent}
            style={isCollectionAddress ? Iconstyles.icon2 : Iconstyles.icon1}
          />
          <div className='btnOrange'>
            <div
              className={`connect-wallet-div btnOrange ${
                menuToogle ? 'menushow' : 'menuhide'
              }`}
            >
              <div className={connectWalletIdBtn} onClick={subMenuFunction}>
                <i className='wallet-adapter-button-start-icon'>
                  {' '}
                  <img src={phantom_icon}></img>
                </i>
                <button className='connect-wallet-id-btn'>
                  {connectbutton}
                </button>
              </div>
              <div
                className={connectWalletBtn}
                onClick={connectWalletBtnFunction}
              >
                <button className='connect-wallet-btn'>Connect Wallet</button>
              </div>
              {subMenuActive ? (
                <div ref={dropdownRef} className={subMenuActive}>
                  <ul className='wallet-adapter-dropdown-list'>
                    <li
                      onClick={() => {
                        navigator.clipboard.writeText(pubKey);
                        showSuccessMessage('Address Copied');
                      }}
                    >
                      Copy address
                    </li>
                    <li onClick={changewallet}>Change wallet</li>
                    <li onClick={disconnectFunction}>Disconnect</li>
                  </ul>{' '}
                </div>
              ) : (
                <div> </div>
              )}
            </div>
          </div>

          {/* <div className="page-heading">SKBSend</div> */}
        </div>

        <div className='main-content'>
          <div className='two-block-wrapper'>
            <div className='right-block-wrapper'>
              <div className='right-block-wrapper-content'>
                <div className='w-full mb-3 lg:mb-0 lg:w-1/3 shrink-0'>
                  <div className='bg-pp rounded-3xl text-white font-bold p-5 px-8 xl:p-8 lg:sticky lg:top-[135px]'>
                    
                    <p className=' leading-tight leading-title'>SKBsend</p>
                    {/* <p className=" leading-tight">SEND multiple Tokens</p> */}
                    <p className=' leading-tight'>
                      SEND Multiple NFTs or Tokens
                    </p>
                    
                    <div className='marketPlace-link' >
                    
                    { !isCollectionAddress ?   <p>SKB NFT Holder : <span className='goldText-highlight'>NO</span>,<br/> Click <a  href="https://magiceden.io/marketplace/solkillerbunnies" target='_blank' rel='noreferer' >here</a> to buy our SKB NFT.</p>
                    :<p>SKB NFT Holder : <span className='redText-highlight'>YES</span></p>}

                    </div>
                    
                    {/* <button className="btnOrange text-white lg:hidden p-4  border-2 border-orange-500 rounded-xl">Learn more!</button> */}

                    <button
                      onClick={() => setShowContent(!showContent)}
                      className='learnMore-Btn'
                    >
                      Learn More !
                    </button>

                    <div className='right-wrapper-content-web'>
                      <div className=' text-sm'>
                        <h4 className='text-red-500 font-lilita text-2xl'>
                          NFTs
                        </h4>
                        <p>
                          The usage costs is{' '}
                          <span className='text-red-500'>
                            {parseFloat(process.env.REACT_APP_FEE_AMOUNT) +
                              parseFloat(
                                process.env.REACT_APP_RENT_AMOUNT
                              )}{' '}
                            SOL
                          </span>{' '}
                          ( {process.env.REACT_APP_RENT_AMOUNT} SOL Rent +{' '}
                          {process.env.REACT_APP_FEE_AMOUNT} SOL Fee) per NFT
                          send.
                        </p>
                        <div className='flex  items-start gap-x-3'>
                          <div>
                            <p>
                              *For sending to many address, please ensure total
                              number of NFTs to be sent is the same as number of
                              recipient addresses.
                            </p>
                            {/* <p className='text-red-500'>Send to One</p> */}
                            {/* <p>Send single/multiple NFTs to one address.</p> */}
                          </div>
                        </div>
                        <div className='flex mb-3 items-start gap-x-3'>
                          <div>
                            {/* <p className='text-red-500'>Send to Many</p>
                            <p>Send multiple NFTs to multiple addresses.</p>
                            <p>The distribution of the NFTs will be random.</p>
                            <p>
                              Total number of NFTs to be sent has to be same as
                              number of recipient addresses.
                            </p> */}
                            {/* <p className="mb-3">
                                                            In case of any failure, the failed addresses
                                                            will be added to retry.
                                                        </p> */}
                          </div>
                        </div>
                      </div>
                      <div className='text-sm'>
                        <h4 className='text-red-500 font-lilita text-2xl'>
                          Tokens
                        </h4>
                        <p className='mb-3'>
                          This service costs{' '}
                          <span className='text-red-500'>
                            {process.env.REACT_APP_FEE_AMOUNT} SOL
                          </span>{' '}
                          per transaction.
                        </p>
                        <p>*Please open a support ticket for any issues.</p>
                        <p>
                          *This service is FREE for holders of SOL
                          Killer Bunnies NFT.
                        </p>
                        {/* <p className='mb-3' style={{ lineHeight: '20px' }}>
                          Send tokens to one address.
                        </p> */}
                        {/* To
                                                    distribute tokens to many wallets, use{" "}
                                                    <a
                                                        className="text-red-500 mb-3"
                                                        style={{ lineHeight: "10px" }}
                                                        href="/foxyshare"
                                                    >
                                                        {" "}
                                                        SKBSend
                                                    </a> */}

                        {/* <p className='mb-3' style={{ lineHeight: '20px' }}>
                          Send tokens to multipe address.
                        </p> */}
                        {/* <p className='mb-3' style={{ lineHeight: '20px' }}>
                          *Please open a support ticket for any issues.
                        </p> */}
                      </div>
                    </div>

                    {showContent ? (
                      <div className='right-wrapper-content-mobile'>
                           <div className=' text-sm'>
                        <h4 className='text-red-500 font-lilita text-2xl'>
                          NFTs
                        </h4>
                        <p>
                          The usage costs is{' '}
                          <span className='text-red-500'>
                            {parseFloat(process.env.REACT_APP_FEE_AMOUNT) +
                              parseFloat(
                                process.env.REACT_APP_RENT_AMOUNT
                              )}{' '}
                            SOL
                          </span>{' '}
                          ( {process.env.REACT_APP_RENT_AMOUNT} SOL Rent +{' '}
                          {process.env.REACT_APP_FEE_AMOUNT} SOL Fee) per NFT
                          send.
                        </p>
                        <div className='flex  items-start gap-x-3'>
                          <div>
                            <p>
                              *For sending to many address, please ensure total
                              number of NFTs to be sent is the same as number of
                              recipient addresses.
                            </p>
                            {/* <p className='text-red-500'>Send to One</p> */}
                            {/* <p>Send single/multiple NFTs to one address.</p> */}
                          </div>
                        </div>
                        <div className='flex mb-3 items-start gap-x-3'>
                          <div>
                            {/* <p className='text-red-500'>Send to Many</p>
                            <p>Send multiple NFTs to multiple addresses.</p>
                            <p>The distribution of the NFTs will be random.</p>
                            <p>
                              Total number of NFTs to be sent has to be same as
                              number of recipient addresses.
                            </p> */}
                            {/* <p className="mb-3">
                                                            In case of any failure, the failed addresses
                                                            will be added to retry.
                                                        </p> */}
                          </div>
                        </div>
                      </div>
                      <div className='text-sm'>
                        <h4 className='text-red-500 font-lilita text-2xl'>
                          Tokens
                        </h4>
                        <p className='mb-3'>
                          This service costs{' '}
                          <span className='text-red-500'>
                            {process.env.REACT_APP_FEE_AMOUNT} SOL
                          </span>{' '}
                          per transaction.
                        </p>
                        <p>*Please open a support ticket for any issues.</p>
                        <p>
                          *This service is FREE for holders of SOL
                          Killer Bunnies NFT.
                        </p>
                        {/* <p className='mb-3' style={{ lineHeight: '20px' }}>
                          Send tokens to one address.
                        </p> */}
                        {/* To
                                                    distribute tokens to many wallets, use{" "}
                                                    <a
                                                        className="text-red-500 mb-3"
                                                        style={{ lineHeight: "10px" }}
                                                        href="/foxyshare"
                                                    >
                                                        {" "}
                                                        SKBSend
                                                    </a> */}

                        {/* <p className='mb-3' style={{ lineHeight: '20px' }}>
                          Send tokens to multipe address.
                        </p> */}
                        {/* <p className='mb-3' style={{ lineHeight: '20px' }}>
                          *Please open a support ticket for any issues.
                        </p> */}
                      </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='left-block-wrapper'>
              <div className={connectHide}>
                <div className='main-tab-flex'>
                  <button
                    className={`bg-red-500 btnBlue${
                      nftBtn ? ' btnBlueOpacity tokenBtn' : ''
                    }`}
                    onClick={nftBlockFunction}
                  >
                    NFTS
                  </button>
                  <button
                    className={`bg-red-500 btnBlue${
                      tokenBtn ? ' btnBlueOpacity tokenBtn' : ''
                    }`}
                    onClick={tokenBlockFunction}
                  >
                    TOKENS
                  </button>
                </div>
                <div className='afterTabDiv horizontalLine'></div>

                <InfiniteScroll
                  dataLength={nfts && nfts.length ? nfts.length : 0}
                  next={() => fetchNextPage()}
                  hasMore={hasNextPage}
                  // loader={<Loading />}
                />

                <div className={nftBlock}>
                  {nfts.length > 0 ? (
                    <Single
                      isFetching={isFetchingNextPage}
                      pubKey={pubKey}
                      nfts={nfts}
                      mmegalist={megalist}
                      getNFTsNewApi={getNFTsNewApi}
                    />
                  ) : (
                    <div></div>
                  )}

                  {isFetchingNextPage ? (
                    <div className='loading-nfts-page'>
                      <Loader />
                      <p>Loading the NFTs.</p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={tokenBlock}>
                  <div className='nft-btns'>
                    <div className='main-tab-flex flex-column'>
                      <p className='nft-heading'>
                        Select the Token(s) you wish to transfer
                      </p>
                      <div className='purple-mobile'>
                        <div className='perpule-btn-div'>
                          <button
                            className='bg-perpule-500'
                            onClick={handleSelectAll}
                            name='selectAll'
                            id='selectAll'
                          >
                            Select All
                          </button>
                          <button
                            className='bg-perpule-500'
                            onClick={handleRemoveAll}
                            name='selectAll'
                            id='selectAll'
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      <div className='token-sendBtn-container'>
                        <button
                          className={`bg-red-500 btnBlue${
                            tokensendto === 'sendtoone'
                              ? ''
                              : ' btnBlueOpacity tokenBtn'
                          }`}
                          onClick={() => displaytokenconfirm('sendtoone')}
                        >
                          Send to One
                        </button>
                        <button
                          className={`bg-red-500 btnBlue${
                            tokensendto === 'sendtomany'
                              ? ''
                              : ' btnBlueOpacity tokenBtn'
                          }`}
                          onClick={() => displaytokenconfirm('sendtomany')}
                        >
                          Send to Many
                        </button>
                      </div>
                    </div>
                    <div className='main-tab-flex flex-column search-container'>
                      <div className='purple-web'>
                        <div className='perpule-btn-div'>
                          <button
                            className='bg-perpule-500'
                            onClick={handleSelectAll}
                            name='selectAll'
                            id='selectAll'
                          >
                            Select All
                          </button>
                          <button
                            className='bg-perpule-500'
                            onClick={handleRemoveAll}
                            name='selectAll'
                            id='selectAll'
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      <div className='search-box-nft'>
                        <SearchIcon className='search-icon' />
                        <input
                          className='search-collection btnTransparent'
                          placeholder='Search collection'
                          id='search'
                          value={searchInput}
                          onChange={(e) => handleSearchInputChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className='scroll2'
                    style={{
                      marginBottom:
                        isCheck && isCheck.length > 0
                          ? tokensendto !== 'sendtoone'
                            ? '13rem'
                            : '10rem'
                          : '5rem',
                    }}
                  >
                    {tokenlist.length > 0 ? (
                      <div>
                        {tokenlist
                          .filter((val) => {
                            if (searchInput == '') {
                              return val;
                            } else if (
                              val.tokenname
                                .toLowerCase()
                                .includes(searchInput.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .map((val, key) => {
                            return (
                              <Token
                                val={val}
                                key={key}
                                hideAmountSendBlock={hideAmountSendBlock}
                                isCheck={isCheck}
                                fhandleClick={fhandleClick}
                                setTokenAmounts={setTokenAmounts}
                              />

                              // <div
                              //     className={`wrapper-token ${isCheck.includes(val.mint)
                              //         ? "activeTokenBtn"
                              //         : ""
                              //         }`}
                              //     key={key}
                              // >
                              //     <div className="">
                              //         <img
                              //             className="w-[60px] h-[60px] object-center object-cover rounded-lg"
                              //             src={val.icon || solo}
                              //             alt="icon"
                              //         />
                              //     </div>
                              //     <div
                              //         className="wrapper-token-flex-1"
                              //         onClick={(e) => fhandleClick(val.mint)}
                              //     >
                              //         <div className="token-text-lg">
                              //             {val.tokenname}
                              //             {/* <input type="checkbox" onChange={handleClick} id={val.mint} checked={isCheck.includes(val.mint)} /> */}
                              //         </div>
                              //         <div>
                              //             <p className="token-text-xs">In Wallet</p>
                              //             <strong className="md:text-lg text-orange-400">
                              //                 {Math.floor(val.amount)}
                              //             </strong>
                              //         </div>
                              //     </div>
                              //     {isCheck.includes(val.mint) ? (
                              //         <div
                              //             className={`wrapper-content-text-center ${hideAmountSendBlock}`}
                              //         >
                              //             <p className="token-text-xs">
                              //                 Amount to Send
                              //             </p>
                              //             <div className="wrapper-content-input-div">
                              //                 <input
                              //                     id="amount"
                              //                     value={tokenssendamounts.get(
                              //                         val.mint
                              //                     )}
                              //                     type="number"
                              //                     className="wrapper-content-input"
                              //                     placeholder="Qty"
                              //                     onChange={(e) =>
                              //                         tokenssendamounts.set(
                              //                             val.mint,
                              //                             e.target.value
                              //                         )
                              //                     }
                              //                 ></input>
                              //                 {/* <button className="max-btn" onClick={() => setamount(val.mint, Math.floor(val.amount))}>Max</button> */}
                              //             </div>
                              //             {/* <Amount tokenssendamounts={tokenssendamounts} val={val} setamount={setamount} /> */}
                              //         </div>
                              //     ) : (
                              //         <div></div>
                              //     )}
                              // </div>
                            );
                          })}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div>
                    {isCheck.length > 0 ? (
                      <div
                        className='nft-open-modal'
                        // open={openToken}
                        // onClose={handleTokenClose}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                      >
                        <div className='modal-style'>
                          <div className='nft-modal-div'>
                            {/* <TextField className="walletInput" label="Wallet address,.sol domain or @twitter" variant="outlined" /> */}

                            {tokensendto === 'sendtoone' ? (
                              <input
                                className='nft-modal-input'
                                type='text'
                                placeholder='Wallet address,.sol domain or @twitter'
                                value={tokenreciver}
                                onChange={(e) =>
                                  setTokenreciver(e.target.value)
                                }
                              />
                            ) : (
                              <textarea
                                className='nft-modal-input-textArea'
                                type='text'
                                placeholder='Wallet address one per line'
                                value={tokenreciver}
                                onChange={(e) =>
                                  setTokenreciver(e.target.value)
                                }
                              />
                            )}
                            <div className='nft-modal-btn'>
                              <button
                                className='modal-reset-btn'
                                variant='outlined'
                                onClick={handleRemoveAll}
                              >
                                Reset
                              </button>
                              {processtranscution ? (
                                <button
                                  className='modal-confirm-btn '
                                  variant='contained'
                                  onClick={bundletokentransfer}
                                >
                                  {confirmbtn}({isCheck.length}){' '}
                                </button>
                              ) : (
                                <button
                                  className='modal-confirm-btn '
                                  variant='contained'
                                >
                                  {' '}
                                  <div className='animate-spin'></div>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className='nft-open-modal'
                        open={open}
                        // onClose={handleClose}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                      >
                        <div className='modal-style'>
                          <div className=''>
                            <p className='not-selected'>
                              No Tokens(s) selected
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={disconnectActive}>
                <p>
                  Please connect your wallet.<br></br>
                </p>
              </div>
              <div className={loadnft}>
                <Loader />
                <p>Loading the NFTs.</p>
              </div>
              <div className={loadtoken}>
                <Loader />
                <p>Loading the Tokens.</p>
              </div>
              <div className={ownedtoken}>
                <p>You own zero Tokens.</p>
              </div>
              <div className={ownednft}>
                <p>You own zero Nfts.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
