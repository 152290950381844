import React from "react";
import "./Checkbox";
import './Box.css';
import './Nftdisplay.css';

var fee = 0;


// let checkboxes = [];
class Checkbox extends React.Component {
    state = {
        selectedCheckboxes: []
    };


    // nft = JSON.parse(localStorage.getItem("nft"));
    // checkbox = JSON.parse(localStorage.getItem("nft"));
    onChange = id => {
        const selectedCheckboxes = this.state.selectedCheckboxes;
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);
        // console.log(this.nft)

        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            selectedCheckboxes.push(id);
        }

        this.setState({
            selectedCheckboxes: selectedCheckboxes
        });
        localStorage.setItem("selectedCheckboxes", JSON.stringify(selectedCheckboxes));
        console.log(selectedCheckboxes);

        fee = 0.000001 * selectedCheckboxes.length;
    };
    // bulkcheck = () => {
    //     let tt = JSON.parse(localStorage.getItem("megalist"))[0];
    //     for (let i = 0; i < tt.length; i++) {
    //         this.onChange(tt[i].tokenAddress);
    //     }
    // }

    render() {
        const { selectedCheckboxes } = this.state;
        return (
            <div className="scroll">
                <h4>Select nft to transfer</h4>
                {/* <button onClick={this.bulkcheck}>checkall </button> */}
                <div className="iframeContainer">
                    <div className="iframecontent" scrolling="yes">
                        {localStorage.getItem("nft") ? (
                            <div>
                                {JSON.parse(localStorage.getItem("megalist")).map((nft, index) => (

                                    <main className='container mx-auto py-36 px-8'>
                                        <div className="headingleft">{nft[0].collectionName} <br /></div>
                                        <div className='grid lg:grid-cols-4 gap-8'>
                                            {nft.map((checkbox, i) => (
                                                <div>
                                                    <label key={checkbox.tokenAddress} >
                                                        <div className="checkcontainer" style={{ padding: "30px" }}>
                                                            <img style={{ width: "120px", height: "130px", borderRadius: "20px" }} src={checkbox.imageUrl}
                                                                onChange={() => this.onChange(checkbox.tokenAddress)}

                                                                alt="nft" />

                                                            <input
                                                                type="checkbox" className="checkcentered"
                                                                onChange={() => this.onChange(checkbox.tokenAddress)}
                                                                selected={selectedCheckboxes.includes(checkbox.tokenAddress)}
                                                            >
                                                            </input>

                                                        </div>
                                                        {/* {checkbox.name} */}

                                                        <br />


                                                    </label>

                                                </div>))}
                                        </div>
                                    </main>
                                ))}

                            </div>) : (<div> </div>)}
                        <br />

                    </div>

                </div>
                <p> Total fee:{fee} Sol</p>

            </div>
        );
    }
}

export default Checkbox;



// import React from "react";
// import "./Checkbox";
// import './Box.css';
// import './Nftdisplay.css';
// import './'
// const checkboxes = [];
// var fee = 0;

// // let checkboxes = [];
// class Checkbox extends React.Component {
//     state = {
//         selectedCheckboxes: []
//     };
//     // nft = JSON.parse(localStorage.getItem("nft"));
//     // checkbox = JSON.parse(localStorage.getItem("nft"));
//     onChange = id => {
//         const selectedCheckboxes = this.state.selectedCheckboxes;
//         // Find index
//         const findIdx = selectedCheckboxes.indexOf(id);
//         // console.log(this.nft)

//         // Index > -1 means that the item exists and that the checkbox is checked
//         // and in that case we want to remove it from the array and uncheck it
//         if (findIdx > -1) {
//             selectedCheckboxes.splice(findIdx, 1);
//         } else {
//             selectedCheckboxes.push(id);
//         }

//         this.setState({
//             selectedCheckboxes: selectedCheckboxes
//         });
//         localStorage.setItem("selectedCheckboxes", JSON.stringify(selectedCheckboxes));
//         console.log(selectedCheckboxes);
//         fee = 0.000001 * selectedCheckboxes.length;
//     };


//     render() {
//         const { selectedCheckboxes } = this.state;
//         return (
//             <div className="scroll">
//                 <h4>Select nft to transfer</h4>
//                 {localStorage.getItem("nft") ? (
//                     <div>
//                         <main className='container mx-auto py-36 px-8'>
//                             <div className='grid lg:grid-cols-4 gap-8'>
//                                 {JSON.parse(localStorage.getItem('nft')).map(checkbox => (

//                                     <label key={checkbox.mint} >
//                                         <div className="checkcontainer" style={{ padding: "30px" }}>
//                                             {/* <img style={{ width: "120px", height: "130px", borderRadius: "20px" }} alt={checkbox.data.name}
//                                                 onChange={() => this.onChange(checkbox.mint)}

//                                             /> */}
//                                             <p>{checkbox.data.name}</p>



//                                             <input
//                                                 type="checkbox" className="checkcentered"
//                                                 onChange={() => this.onChange(checkbox.mint)}
//                                                 selected={selectedCheckboxes.includes(checkbox.mint)}
//                                             >
//                                             </input>

//                                         </div>
//                                         {checkbox.data.name}

//                                         <br />


//                                     </label>

//                                 ))}
//                             </div>
//                         </main>

//                     </div>) : (<div> </div>)}
//                 <br />
//                 <br />
//                 <p> Total fee:{fee} Sol</p>

//             </div>
//         );
//     }
// }

// export default Checkbox;
