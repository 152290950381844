import React from 'react';
import { useState, useEffect } from 'react';
import { Connection, PublicKey } from '@solana/web3.js';
import { getTokenAccounts } from './Components/tokenquery';

function Tokendisplays({ tokensss, bala, MM }) {

    return (
        <div>
            <p>token address-- {tokensss} -- balance {Number(bala)} </p>
        </div>
    );
}

export default Tokendisplays;
