// import ButtonAppBar from "./Components/Appbar";
// import Display from "./Display";
// // import NFT from "./Nftwallet";
// function App() {


//   return (
//     <div>
//       {/* <NFT /> */}
//       <div>
//         <Display />
//       </div>
//       {/* <ButtonAppBar /> */}

//     </div>
//   );
// }

// export default App;

// import logo from './logo.svg';
import './App1.css';
import './css/style.css';
import './css/home.css';
import React from "react";
import {
  Routes,
  Route

} from "react-router-dom";

import Nft from './Pages/nft';
import Display from './Display';



function App() {
  return (
    <Routes>
      {/* <Route exact path="/" element={<Display />} /> */}
      <Route exact path="/" element={<Nft />} />


    </Routes>
  );
}

export default App;