import { AmpStoriesOutlined } from "@material-ui/icons";
import React from "react";
var fee = 0;
class Tokencheckbox extends React.Component {
    state = {
        selectedCheckboxes: []
    };

    // nft = JSON.parse(localStorage.getItem("nft"));
    // checkbox = JSON.parse(localStorage.getItem("nft"));
    onChange = id => {
        const selectedCheckboxes = this.state.selectedCheckboxes;
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);
        // console.log(this.nft)

        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
        } else {
            selectedCheckboxes.push(id);
        }

        this.setState({
            selectedCheckboxes: selectedCheckboxes
        });
        localStorage.setItem("selectedTokenCheckboxes", JSON.stringify(selectedCheckboxes));
        console.log(selectedCheckboxes);
        fee = selectedCheckboxes.length * 0.000001;

    };

    render() {
        const { selectedCheckboxes } = this.state;

        return (
            <div className="tokenscroll">

                <h4>Select tokens to transfer</h4>
                <div className="tokeniframeContainer">
                    <div className="iframecontent" scrolling="yes">
                        {localStorage.getItem("token") ? (
                            <div>
                                {JSON.parse(localStorage.getItem('token')).map(checkbox => (

                                    <label key={checkbox.mint}>
                                        {checkbox.tokenname}--
                                        {checkbox.amount}
                                        <input
                                            type="checkbox"
                                            onChange={() => this.onChange(checkbox.mint)}
                                            selected={selectedCheckboxes.includes(checkbox.mint)}
                                        />
                                        <br />

                                    </label>
                                ))}</div>) : (<div> </div>)}



                    </div>

                </div>
                <p> Total fee:{fee} Sol</p>
            </div>
        );
    }
}

export default Tokencheckbox;
