import ReactDOM from 'react-dom';
import Singlebox from './Singlebox';
import React, { useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import soloKillerBunnies from '../assets/solo-killer-bunnies.webp';
// import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from '@mui/material/Input';
import WebFont from 'webfontloader';
import NFTJSONDATA from '../assets/nft.json';
import TOKENJSONDATA from '../assets/token.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getNFTs from '../Display.js';
import phantom_icon from '../assets/Phantom_icon.svg';
import { getTokenAccounts } from '../Components/tokenquery';
import {
  Connection,
  GetProgramAccountsFilter,
  LAMPORTS_PER_SOL,
  clusterApiUrl,
} from '@solana/web3.js';
import TEMPLATEJSONDATA from '../assets/template.json';
import { bundletransferTokenHandler } from '../Components/bundletransfer';
import axios from 'axios';
import { render } from '@testing-library/react';
import '../App1.css';
import '../css/home.css';
import '../css/style.css';
import solo from '../assets/solo.png';

const Single = ({ pubKey, nfts, mmegalist, getNFTsNewApi, isFetching }) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isCollectionCheck, setIsCollectionCheck] = useState([]);
  const [list, setList] = useState([]);
  const [megalist, setMegalist] = useState([]);
  const [open, setOpen] = React.useState(true);
  const [nftreciverlist, setNftreciverlist] = useState('');
  const [confirmbtn, setConfirmbtn] = useState('Confirm');
  const [nftsendto, setNftsendto] = useState('sendtoone');
  const [searchInput, setSearchInput] = useState('');
  const [nftselectcount, setNftselectcount] = useState([]);
  const [processtranscution, setProcesstranscution] = useState(true);
  const style = {
    position: 'fixed',
    bottom: '0%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: '30%',
    // bgcolor: 'rgba(17, 24, 39, 0.8)',
    // boxShadow: 24,
    // p: 4,
    background: 'rgba(17, 24, 39, .8)',
    margin: '7px',
    padding: '10px',
    heigth: '300px',
    marginBottom: '15px',
    borderRadius: '15px',
  };

  const style1 = {
    position: 'fixed',
    bottom: '0%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: 600,
    // bgcolor: 'rgba(17, 24, 39, 0.8)',
    // boxShadow: 24,
    // p: 4,
  };
  useEffect(() => {
    // setList(JSON.parse(localStorage.getItem("nft")));
    setList(nfts);
    console.log(list);
    // setMegalist(JSON.parse(localStorage.getItem("megalist")));
    setMegalist(mmegalist);
    console.log(megalist);
    let local = [];
    for (let i = 0; i < megalist.length; i++) {
      local.push(0);
    }
    setNftselectcount(local);
  }, [mmegalist]);
  useEffect(() => {
    let local = [];
    for (let i = 0; i < megalist.length; i++) {
      local.push(0);
    }
    setNftselectcount(local);
  }, [megalist]);

  const handleRemoveAll = (e) => {
    setIsCheck([]);
    setIsCheckAll(false);
    setIsCollectionCheck([]);
    let local = [];
    for (let i = 0; i < megalist.length; i++) {
      local.push(0);
    }
    setNftselectcount(local);
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    
    setIsCheck(list.map((li) => li.tokenAddress));
    
    setIsCollectionCheck(megalist.map((li) => li[0].collectionAddress));
    if (isCheckAll) {
      // setIsCheck([]);
      // setIsCollectionCheck([]);
      // let local = [];
      // for (let i = 0; i < megalist.length; i++) {
      //     local.push(0);
      // }
      // setNftselectcount(local);
    } else {
      let local = [];
      for (let i = 0; i < megalist.length; i++) {
        local.push(megalist[i].length);
      }
      setNftselectcount(local);
    }
  };

  const getCollectionId = (id) => {
    let collection = '';
    for (let i in megalist) {
      for (let j in megalist[i]) {
        
        if (megalist[i][j].tokenAddress === id) {
          
          collection = megalist[i][j].collectionAddress;
          return collection;
        }
      }
    }
  };

  const checkId = (id) => {
    let obj = {};

    for (let i in isCheck) {
      let collectionId = getCollectionId(isCheck[i]);

      if (collectionId) {
        if (!obj[collectionId]) {
          obj[collectionId] = 1;
        } else {
          obj[collectionId] += 1;
        }
      }
    }

    const matchCollectionId = getCollectionId(id);

    if (obj[matchCollectionId] === 1) {
      setIsCollectionCheck(
        isCollectionCheck.filter((item) => item !== matchCollectionId)
      );
    }
  };

  const handlecollectionselectAll = (e) => {
    const { id, checked } = e.target;
    if (!checked) {
      let tempIsCheck = [...isCheck];
      let count = 0;
      let index = -1;

      for (let i = 0; i < megalist.length; i++) {
        
        if (megalist[i][0].collectionAddress === id) {
          for (let j = 0; j < megalist[i].length; j++) {
            
            if (isCheck.includes(megalist[i][j].tokenAddress)) {
              index = i;
              tempIsCheck = tempIsCheck.filter(
                
                (data) => data !== megalist[i][j].tokenAddress
              );
              console.log(tempIsCheck);
            }
          }
        }
      }

      setIsCheck([...tempIsCheck]);
      if (index >= 0) {
        let temp = [...nftselectcount];
        temp[index] = 0;
        setNftselectcount(temp);
      }

      setIsCollectionCheck(isCollectionCheck.filter((item) => item !== id));
    } else {
      setIsCollectionCheck([...isCollectionCheck, id]);
      let tempIsCheck = [...isCheck];
      let count = 0;
      let index = -1;
      for (let i = 0; i < megalist.length; i++) {
        
        if (megalist[i][0].collectionAddress === id) {
          for (let j = 0; j < megalist[i].length; j++) {
            count += 1;
            index = i;
            
            tempIsCheck.push(megalist[i][j].tokenAddress);
          }
        }
      }

      setIsCheck([...tempIsCheck]);
      if (index >= 0) {
        let temp = [...nftselectcount];
        temp[index] = count;
        setNftselectcount(temp);
      }
    }
  };
  const handleSearchInputChange = (e) => {
    const { id, value } = e.target;
    if (id === 'search') {
      setSearchInput(value);
    }
  };

  const handleClick = (e) => {
    let k = 1;
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      checkId(id);
      setIsCheck(isCheck.filter((item) => item !== id));
      k = -1;
    }
    let localcount = [...nftselectcount];
    let flag = 0;
    for (let i = 0; i < megalist.length; i++) {
      for (let j = 0; j < megalist[i].length; j++) {
        
        if (megalist[i][j].tokenAddress === id) {
          flag = i;
        }
      }
    }
    localcount[flag] = localcount[flag] + k;
    setNftselectcount(localcount);
  };
  function displaynftconfirm(nftsend) {
    console.log(nftsend);
    setNftsendto(nftsend);
  }
  const showToastMessage = () => {
    toast.error('Error signing transactions', {
      position: toast.POSITION.BOTTOM_LEFT,
      className: 'toast-message',
    });
  };
  const showsucessToastMessage = () => {
    toast.success(' transactions sucessful', {
      position: toast.POSITION.BOTTOM_LEFT,
      className: 'toast-message',
    });
  };
  const showErrorMessage = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_LEFT,
      className: 'toast-message',
    });
  };

  async function bundlenfttransfer() {
    if (!nftreciverlist) {
      showErrorMessage('Invalid Address');
      return;
    }

    let mintaddresslist = isCheck;
    setProcesstranscution(false);
    if (nftsendto === 'sendtomany') {
      var str = nftreciverlist;
      var recieveraddresslist = str.split(',');
      let amountlist = [];
      for (let i = 0; i < mintaddresslist.length; i++) {
        amountlist.push(1);
      }
      if (recieveraddresslist.length == mintaddresslist.length) {
        setConfirmbtn('');
        let result = await bundletransferTokenHandler(
          mintaddresslist,
          recieveraddresslist,
          amountlist,
          pubKey,
          process.env.REACT_APP_FEE_RECIEVER
        );
        getNFTsNewApi();
        setConfirmbtn('confirm');
        setIsCheck([]);
        setIsCheckAll(false);
        setIsCollectionCheck([]);
        let local = [];
        for (let i = 0; i < megalist.length; i++) {
          local.push(0);
        }
        setNftselectcount(local);
      } else {
        showErrorMessage('please enter receiver address correctly');
      }
    } else {
      let mintaddresslist = isCheck;
      var str = nftreciverlist;
      let recieveraddresslist = [];
      for (let i = 0; i < mintaddresslist.length; i++) {
        recieveraddresslist.push(str);
      }
      let amountlist = [];
      for (let i = 0; i < mintaddresslist.length; i++) {
        amountlist.push(1);
      }
      if (recieveraddresslist.length == mintaddresslist.length) {
        setConfirmbtn('');
        let result = await bundletransferTokenHandler(
          mintaddresslist,
          recieveraddresslist,
          amountlist,
          pubKey,
          process.env.REACT_APP_FEE_RECIEVER
        );
        getNFTsNewApi();
        setConfirmbtn('confirm');
        setIsCheck([]);
        setIsCheckAll(false);
        setIsCollectionCheck([]);
        let local = [];
        for (let i = 0; i < megalist.length; i++) {
          local.push(0);
        }
        setNftselectcount(local);
      } else {
        showErrorMessage('please enter receiver address correctly');
      }
    }
    setProcesstranscution(true);
  }
  console.log(isCollectionCheck);
  console.log(isCheck);
  console.log(nftselectcount);
  const catalog = megalist
    .map((data) =>
      data.filter((val) => {
        if (searchInput == '') {
          return val;
        } else if (
          
          val.name.toLowerCase().includes(searchInput.toLowerCase())
        ) {
          return val;
        }
      })
    )
    .map((val, ff) => {
      if (val && !val.length) {
        return null;
      }
      return (
        <>
          <div className='search-box-main-wrapper ' key={ff}>
            <div className='search-box-nft'>
              {/* <div className="search-box-flex">
                            <label className="container rounded-lg">
                                <input type="checkbox" className="checkmark"
                                    key={ff}
                                    name="selectAll"
                                    id={val[0].nft_collection_id}
                                    onClick={handlecollectionselectAll}
                                    checked={isCollectionCheck.includes(val[0].nft_collection_id)} />
                                <span className="checkmark"></span>
                            </label>
                            <div className="checkbox-all-nft">{val[0].nft_collection_name}</div>
                        </div> */}

              <div className='selected-count'>
                <strong className='text-white'>{nftselectcount[ff]}</strong>{' '}
                Selected
              </div>
            </div>

            <div className='image-wrapper'>
              {val.map((id, k) => (
                
                <Singlebox
                  key={k}
                  image={id.imageUrl}
                  type='checkbox'
                  name={id.name}
                  id={id.tokenAddress}
                  handleClick={handleClick}
                  isChecked={isCheck.includes(id.tokenAddress)}
                />
              ))}
            </div>
          </div>
        </>
      );
    });

  return (
    <div>
      <div className='nft-btns'>
        <div className='main-tab-flex flex-column'>
          <p className='nft-heading'>Select the NFT(s) you wish to transfer</p>
          <div className='purple-mobile'>
            <div className='perpule-btn-div'>
              <button
                className='bg-perpule-500 btnTransparent'
                onClick={handleSelectAll}
                name='selectAll'
                id='selectAll'
              >
                Select All
              </button>
              <button
                className='bg-perpule-500 btnTransparent'
                onClick={handleRemoveAll}
                name='selectAll'
                id='selectAll'
              >
                Reset
              </button>
            </div>
          </div>

          <div className='sendBtn-container'>
            <button
              className={`bg-red-500 btnBlue${
                nftsendto === 'sendtoone' ? '' : ' btnBlueOpacity tokenBtn'
              }`}
              onClick={() => displaynftconfirm('sendtoone')}
            >
              Send to One
            </button>
            <button
              className={`bg-red-500 btnBlue${
                nftsendto === 'sendtomany' ? '' : ' btnBlueOpacity tokenBtn'
              }`}
              onClick={() => displaynftconfirm('sendtomany')}
            >
              Send to Many
            </button>
          </div>
        </div>
        <div className='main-tab-flex flex-column search-container'>
          <div className='purple-web'>
            <div className='perpule-btn-div'>
              <button
                className='bg-perpule-500 btnTransparent'
                onClick={handleSelectAll}
                name='selectAll'
                id='selectAll'
              >
                Select All
              </button>
              <button
                className='bg-perpule-500 btnTransparent'
                onClick={handleRemoveAll}
                name='selectAll'
                id='selectAll'
              >
                Reset
              </button>
            </div>
          </div>
          <div className='search-box-nft'>
            <SearchIcon className='search-icon' />
            <input
              className='search-collection btnTransparent'
              placeholder='Search nft'
              id='search'
              value={searchInput}
              onChange={(e) => handleSearchInputChange(e)}
            />
          </div>
        </div>
      </div>
      <div
        className='scroll2'
        style={{
          marginBottom: isFetching
            ? '3rem'
            : isCheck && isCheck.length > 0
            ? nftsendto !== 'sendtoone'
              ? '13rem'
              : '10rem'
            : '5rem',
        }}
      >
        {catalog}
      </div>
      {isCheck.length > 0 ? (
        <div
          className='nft-open-modal'
          open={open}
          // onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modal-style'>
            <div className='nft-modal-div'>
              {nftsendto === 'sendtoone' ? (
                <input
                  className='nft-modal-input horizontalLine'
                  type='text'
                  placeholder='Wallet address,.sol domain or @twitter'
                  onChange={(e) => setNftreciverlist(e.target.value)}
                  value={nftreciverlist}
                />
              ) : (
                <textarea
                  className='nft-modal-input-textArea'
                  type='text'
                  placeholder='Wallet address one per line'
                  onChange={(e) => setNftreciverlist(e.target.value)}
                  value={nftreciverlist}
                />
              )}
              <div className='nft-modal-btn'>
                <button
                  className='modal-reset-btn btnTransparent'
                  variant='outlined'
                  onClick={handleRemoveAll}
                >
                  Reset
                </button>
                {processtranscution ? (
                  <button
                    className='modal-confirm-btn btnOrange'
                    variant='contained'
                    onClick={() => bundlenfttransfer()}
                  >
                    {confirmbtn}({isCheck.length})
                  </button>
                ) : (
                  <button
                    className='modal-confirm-btn btnOrange'
                    variant='contained'
                  >
                    <div className='animate-spin'></div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className='nft-open-modal'
          open={open}
          // onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='modal-style1'>
            <div className=''>
              <p className='not-selected'>No Nfts(s) selected</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Single;
