import React, { useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import soloKillerBunnies from '../assets/solo-killer-bunnies.webp';
// import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Input from '@mui/material/Input';
import WebFont from 'webfontloader';
import NFTJSONDATA from '../assets/nft.json';
import TOKENJSONDATA from '../assets/token.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getNFTs from '../Display.js';
import phantom_icon from '../assets/Phantom_icon.svg';
import { getTokenAccounts } from '../Components/tokenquery';
import {
  Connection,
  GetProgramAccountsFilter,
  LAMPORTS_PER_SOL,
  clusterApiUrl,
} from '@solana/web3.js';
import TEMPLATEJSONDATA from '../assets/template.json';
import { bundletransferTokenHandler } from '../Components/bundletransfer';
import axios from 'axios';
import { render } from '@testing-library/react';
import '../App1.css';

function Checkbox({ megalist, val }) {
  const [selectedNft, setSelectedNft] = useState([]);
  const [nftselectcount, setNftselectcount] = useState([]);

  function handlenftchange(id, key) {
    var selectedCheckboxes = selectedNft;
    const findIdx = selectedCheckboxes.indexOf(id);
    // console.log(this.nft)

    // Index > -1 means that the item exists and that the checkbox is checked
    // and in that case we want to remove it from the array and uncheck it
    if (findIdx > -1) {
      selectedCheckboxes.splice(findIdx, 1);
    } else {
      selectedCheckboxes.push(id);
    }

    setSelectedNft(selectedCheckboxes);
    localStorage.setItem(
      'selectedCheckboxes',
      JSON.stringify(selectedCheckboxes)
    );
    console.log(selectedCheckboxes);
    console.log(selectedNft.includes(id));
    let localcount = nftselectcount;
    let k = 0;
    let tt = megalist[key];
    for (let i = 0; i < tt.length; i++) {
      
      if (selectedNft.includes(tt[i].tokenAddress)) {
        k++;
      }
      localcount[key] = k;
    }
    setNftselectcount(localcount);
    console.log(nftselectcount);
  }
  function bulk(id) {
    let tt = megalist[id];
    for (let i = 0; i < tt.length; i++) {
      
      handlenftchange(tt[i].tokenAddress, id);
    }
  }
  return (
    <div className='image-wrapper'>
      <button onClick={() => bulk(0)}>bulk</button>

      {val.map((element, k) => (
        <div className='image-border' key={k}>
          <label className='container'>
            
            <input
              type='checkbox'
              className='checkmark'
              onChange={() => handlenftchange(element.tokenAddress, k)}
              selected={selectedNft.includes(element.tokenAddress)}
            />
            <span className='checkmark'></span>
          </label>
          <img src={element.nft_image} />
          <div className='image-overlay'>
            
            <div className='nft-image-overlay-hover-text'>{element.name}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Checkbox;
