import './App.css';
import { useState } from 'react';
import Nftdisplay from './Nftdisplay';
import './Display.css';
import axios from 'axios';
import { useEffect } from 'react';
import Checkbox from './Checkbox';
import './Box.css';
import {
  Connection,
  GetProgramAccountsFilter,
  LAMPORTS_PER_SOL,
  clusterApiUrl,
} from '@solana/web3.js';
import { getTokenAccounts } from './Components/tokenquery';
import Tokendisplays from './Tokendisplay';
import Tokencheckbox from './Tokencheckbox';
import { bundletransferTokenHandler } from './Components/bundletransfer';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import green from '@material-ui/core/colors/green';
import { CircularProgress } from '@material-ui/core';
import Inputcheck from './Inputcheck';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Alert from '@mui/material/Alert';

var theblockchainapi = require('theblockchainapi');
var defaultClient = theblockchainapi.ApiClient.instance;
var APIKeyID = defaultClient.authentications['APIKeyID'];
APIKeyID.apiKey = 'Lv4yPon55s6BSvo';
var APISecretKey = defaultClient.authentications['APISecretKey'];
APISecretKey.apiKey = '4jrzvbo4wGpxlG6';
let apiInstance = new theblockchainapi.SolanaWalletApi();

function Display() {
  // You can also provide a custom RPC endpoint.
  const [nftsendto, setNftsendto] = useState('');
  // const [tokensendto, setTokensendto] = useState("");
  const [verfiyed, setVerfiyed] = useState('');
  const [checktokenamountlist, setchecktokenamountlist] = useState([]);
  const [pubKey, setPubKey] = useState();
  const [connectbutton, setconnectbutton] = useState('Connect Wallet');
  const [feereceiver, setfeereceiver] = useState();
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transferloading, setTransferloading] = useState(false);
  const [buttonloading, setButtonloading] = React.useState(true);
  function handleClick() {
    setButtonloading(true);
    console.log();
  }
  const connectWallet = async () => {
    const provider = window.solana;
    // console.log(provider);
    if (provider) {
      //setCount(count + 1);
      await window.solana.connect();
      // window.solana.on("connect", () => console.log("connect"));
      getConnectedWallet();
    } else window.open('https://phantom.app/', '_blank');
  };
  const getConnectedWallet = async () => {
    const provider = await window.solana;
    if (provider) {
      setPubKey(provider.publicKey);
      if (provider.publicKey) {
        setconnectbutton(provider.publicKey.toString().slice(0, 9));
      }
      // console.log("Is Phantom installed?  ", provider.isPhantom);
      //return provider;
    } else console.log('Try to connect again');
  };
  //var ownerkey = aw
  useEffect(() => {
    getConnectedWallet();
    connectWallet();
  }, []);
  useEffect(() => {
    if (pubKey) {
      getNFTs();
    }
  }, [pubKey]);

  const [nfts, setNfts] = useState([]);
  const [shownft, setShownft] = useState(false);
  const [showtoken, setShowntoken] = useState(false);
  const [nftreciverlist, setNftreciverlist] = useState('');
  const [tokenreceiveamount, settokenreceiveamount] = useState('');
  const [tokenreciver, settokenreciver] = useState('');

  async function getNFTs() {
    // getNftAccounts();
    // const rpcEndpoint = 'https://api.devnet.solana.com';
    // const solanaConnection = new Connection(rpcEndpoint);
    // let token = await Nftquery(pubKey, solanaConnection);
    setShowntoken(false);

    setLoading(true);

    setShownft(false);
    // let network = process.env.REACT_APP_NETWORK; // String | The network ID (devnet, mainnet-beta)

    (() => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const data = {
        jsonrpc: '2.0',
        id: 1,
        method: 'qn_fetchNFTs',
        params: {
          wallet: 'F9vHiTZ6vym11VVwEBWtfurACBg4yURC99V9XtqxqgrE', //pubKey.toString(),
          omitFields: ['provenance', 'traits'],
          page: 1,
          perPage: 40,
        },
      };
      axios
        .post(
          'https://cool-aged-arm.solana-mainnet.discover.quiknode.pro/a412ebc2da36ee3eee38494d4c718a0bf2376453/',
          data,
          config
        )
        .then(function (response) {
          console.log(response);
          // handle success
          console.log(response.data.result.assets);
          let fetchnfts = response.data.result.assets;
          let nftlist = [];
          nftlist.push(fetchnfts[0]);
          for (let i = 0; i < fetchnfts.length; i++) {
            if (
              
              fetchnfts[i].collectionAddress !== fetchnfts[0].collectionAddress
            ) {
              nftlist.push(fetchnfts[i]);
            }
          }
          let nft = [];
          for (let k = 0; k < nftlist.length; k++) {
            for (let i = 0; i < fetchnfts.length; i++) {
              if (
                
                nftlist[k].collectionAddress === fetchnfts[i].collectionAddress
              ) {
                nft.push(fetchnfts[i]);
              }
            }
          }
          let meganftlist = [];
          for (let i = 0; i < nftlist.length; i++) {
            let nftlist1 = [];
            for (let k = 0; k < nft.length; k++) {
              
              if (nftlist[i].collectionAddress === nft[k].collectionAddress) {
                nftlist1.push(nft[k]);
              }
            }
            meganftlist.push(nftlist1);
          }
          console.log(meganftlist);
          localStorage.setItem('megalist', JSON.stringify(meganftlist));
          console.log(nft);
          setNfts(nft);
          localStorage.setItem('nft', JSON.stringify(nft));
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    })();

    setShownft(true);
    setLoading(false);
  }
  async function getdevnetNFTs() {
    // getNftAccounts();
    // const rpcEndpoint = 'https://api.devnet.solana.com';
    // const solanaConnection = new Connection(rpcEndpoint);
    // let token = await Nftquery(pubKey, solanaConnection);
    setShowntoken(false);
    setShownft(false);
    setLoading(true);
    console.log(pubKey.toString());
    // let network = process.env.REACT_APP_NETWORK; // String | The network ID (devnet, mainnet-beta)
    let network = 'devnet'; // String | The network ID (devnet, mainnet-beta)
    const result = await apiInstance.solanaGetNFTsBelongingToWallet(
      network,
      pubKey.toString()
    );
    console.log(result);
    localStorage.setItem('nft', JSON.stringify(result.nfts_metadata));
    setNfts(result.nfts_metadata);
    let nftdata = [];
    for (let i = 0; i < result.nfts_metadata.length; i++) {
      nftdata.push(result.nfts_metadata[i].data.uri);
    }
    console.log(result.nfts_metadata);
    setShownft(true);
    setLoading(false);
  }

  function walletconnect() {
    getConnectedWallet();
    connectWallet();
    if (pubKey) {
      setconnectbutton(pubKey.toString().slice(0, 9));
    }
  }

  async function gettokenfuncation() {
    setShownft(false);
    setShowntoken(false);
    setLoading(true);
    const rpcEndpoint = process.env.REACT_APP_RCP_END_POINT; //                  'https://api.devnet.solana.com';
    const solanaConnection = new Connection(rpcEndpoint);
    let token = await getTokenAccounts(pubKey, solanaConnection);
    setTokens(token);
    localStorage.setItem('token', JSON.stringify(token));
    setShowntoken(true);
    setLoading(false);
  }

  function checkbalance(mintaddresslist) {
    let balance = localStorage.getItem('balance');
    console.log(balance);
    let lamportsI = mintaddresslist.length * LAMPORTS_PER_SOL * 0.000001; //service fee
    if (balance < lamportsI) {
      alert('Insufficient balance');
      return false;
    } else {
      return true;
    }
  }
  async function bundlenfttransfer() {
    let mintaddresslist = JSON.parse(
      localStorage.getItem('selectedCheckboxes')
    );
    // let t = checkbalance(mintaddresslist);
    // if (t) {
    if (verfiyed) {
      if (nftsendto === 'sendtomany') {
        var str = nftreciverlist;
        var recieveraddresslist = str.split(',');
        let amountlist = [];
        for (let i = 0; i < mintaddresslist.length; i++) {
          amountlist.push(1);
        }
        if (recieveraddresslist.length == mintaddresslist.length) {
          setTransferloading(true);
          let result = await bundletransferTokenHandler(
            mintaddresslist,
            recieveraddresslist,
            amountlist,
            pubKey,
            verfiyed
          );
          setTransferloading(false);
        } else {
          alert('please enter recever address');
        }
      } else {
        let mintaddresslist = JSON.parse(
          localStorage.getItem('selectedCheckboxes')
        );
        var str = nftreciverlist;
        let recieveraddresslist = [];
        for (let i = 0; i < mintaddresslist.length; i++) {
          recieveraddresslist.push(str);
        }
        let amountlist = [];
        for (let i = 0; i < mintaddresslist.length; i++) {
          amountlist.push(1);
        }
        if (recieveraddresslist.length == mintaddresslist.length) {
          setTransferloading(true);
          let result = await bundletransferTokenHandler(
            mintaddresslist,
            recieveraddresslist,
            amountlist,
            pubKey,
            verfiyed
          );
          setTransferloading(false);
        } else {
          alert('please enter recever address');
        }
      }
    }
    // }
  }

  async function bundletokentransfer() {
    let mintaddresslist = JSON.parse(
      localStorage.getItem('selectedTokenCheckboxes')
    );
    let tokenslist = JSON.parse(localStorage.getItem('tokens'));
    let amountlist = tokenreceiveamount.split(',');
    let b = true;
    for (let i = 0; i < mintaddresslist.length; i++) {
      tokens.forEach((element) => {
        if (element.mint === mintaddresslist[i]) {
          console.log(Number(element.amount));
          console.log(Number(amountlist[i]));
          if (Number(element.amount) < Number(amountlist[i])) {
            alert('Insufficient token balance');
            b = false;
            return false;
          }
        }
      });
    }
    if (b) {
      // let t = checkbalance(mintaddresslist);
      // if (t) {
      if (verfiyed) {
        if (nftsendto === 'sendtomany') {
          let tokenslist = JSON.parse(localStorage.getItem('tokens'));
          var str = tokenreciver;
          var recieveraddresslist = str.split(',');
          let amountlist = tokenreceiveamount.split(',');
          for (let i = 0; i < mintaddresslist.length; i++) {
            amountlist[i] = Number(amountlist[i]) * 1000000000;
            console.log(amountlist[i]);
          }

          if (
            recieveraddresslist.length == mintaddresslist.length &&
            amountlist.length == mintaddresslist.length
          ) {
            setTransferloading(true);
            let result = await bundletransferTokenHandler(
              mintaddresslist,
              recieveraddresslist,
              amountlist,
              pubKey,
              verfiyed
            );
            setTransferloading(false);
          } else {
            alert('please enter details correctly');
          }
        } else {
          let tokenslist = JSON.parse(localStorage.getItem('tokens'));
          let mintaddresslist = JSON.parse(
            localStorage.getItem('selectedTokenCheckboxes')
          );
          var str = tokenreciver;
          let amountlist = tokenreceiveamount.split(',');

          let recieveraddresslist = [];
          for (let i = 0; i < mintaddresslist.length; i++) {
            recieveraddresslist.push(tokenreciver);
          }
          for (let i = 0; i < mintaddresslist.length; i++) {
            amountlist[i] = Number(amountlist[i]) * 1000000000;
            console.log(amountlist[i]);
          }

          if (
            recieveraddresslist.length == mintaddresslist.length &&
            amountlist.length == mintaddresslist.length
          ) {
            setTransferloading(true);
            let result = await bundletransferTokenHandler(
              mintaddresslist,
              recieveraddresslist,
              amountlist,
              pubKey,
              verfiyed
            );
            setTransferloading(false);
          } else {
            alert('please enter details correctly');
          }
        }
      } else {
        alert('please enter fee reciver address');
      }
      // }
    }
  }

  function loadfield() {
    let mintaddresslist = JSON.parse(
      localStorage.getItem('selectedCheckboxes')
    );
    let temp = [];
    for (let i = 0; i < mintaddresslist.length; i++) {
      temp.push(i);
    }
    setchecktokenamountlist(temp);
  }

  const useStyles = makeStyles((theme) => ({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    customColor: {
      // or hex code, this is normal CSS background-color
      backgroundColor: green[500],
    },
    customHeight: {
      minHeight: 150,
    },
    offset: theme.mixins.toolbar,
  }));
  const classes = useStyles();
  const [example, setExample] = useState('primary');
  const isCustomColor = example === 'customColor';
  const isCustomHeight = example === 'customHeight';
  async function verfiyaddress() {
    if (feereceiver.length >= 32 && feereceiver.length <= 44) {
      setVerfiyed(feereceiver);
      alert('address saved');
    } else {
      alert('address is not valid');
    }
  }

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    '.MuiFormControlLabel-label': checked && {
      color: theme.palette.primary.main,
    },
  }));
  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
    console.log(radioGroup.value);
    let checked = false;
    setNftsendto(radioGroup.value);
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  MyFormControlLabel.propTypes = {
    /**
     * The value of the component.
     */
    value: PropTypes.any,
  };

  return (
    <div className='App'>
      <AppBar
        color={isCustomColor || isCustomHeight ? 'primary' : example}
        className={`${isCustomColor && classes.customColor} ${
          isCustomHeight && classes.customHeight
        }`}
      >
        <Toolbar>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={getNFTs}
          >
            NFTS
          </IconButton>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={gettokenfuncation}
          >
            Tokens
          </IconButton>
          <Typography variant='h6' className={classes.title}></Typography>
          <IconButton
            color='inherit'
            onClick={() => setExample('default')}
          ></IconButton>
          <IconButton color='inherit' onClick={walletconnect}>
            {connectbutton}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <br />
      <input
        type='string'
        placeholder='Enter fee reciever address'
        size='100'
        onChange={(e) => setfeereceiver(e.target.value)}
        value={feereceiver}
      />
      <button onClick={verfiyaddress}>Save address</button>
      <br />
      <p>Single NFT & Token transfer fee : 0.000001 Sol </p>

      {transferloading ? (
        <div className='transferloading'>
          <p>Transaction processing </p>
          <br /> <CircularProgress />{' '}
        </div>
      ) : (
        <div> </div>
      )}

      {showtoken ? (
        <div>
          {/* {tokens.map((token, MM) => (
                    <div>
                        <Tokendisplays tokensss={token.mint} bala={token.amount} MM={MM} />
                    </div>
                ))} */}

          {tokens.length > 0 ? (
            <div>
              <Tokencheckbox />
              <div className='tokentransfer'>
                <TextField
                  type='string'
                  fullWidth
                  label='Enter reciever address separated by comma'
                  id='fullWidth'
                  onChange={(e) => settokenreciver(e.target.value)}
                  value={tokenreciver}
                />
                <br />
                <br />
                <TextField
                  type='string'
                  fullWidth
                  label='Enter reciever amount separated by comma'
                  id='fullWidth'
                  onChange={(e) => settokenreceiveamount(e.target.value)}
                  value={tokenreceiveamount}
                />
                <br />
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  defaultValue='sendtoone'
                >
                  <MyFormControlLabel
                    value='sendtoone'
                    label='Send To One'
                    control={<Radio />}
                  />
                  <MyFormControlLabel
                    value='sendtomany'
                    label='Send To Many'
                    control={<Radio />}
                  />
                  <LoadingButton
                    size='large'
                    onClick={bundletokentransfer}
                    loading={loading}
                    loadingPosition='end'
                    variant='contained'
                  >
                    transfer
                  </LoadingButton>
                </RadioGroup>
              </div>
            </div>
          ) : (
            <div>
              <br />
              <br />
              <br />
              <br />
              you own zero Tokens{' '}
            </div>
          )}
        </div>
      ) : (
        <div> </div>
      )}

      <div className='App'>
        {shownft ? (
          <div>
            {/* <main className='container mx-auto py-36 px-8'>
                        <div className='grid lg:grid-cols-4 gap-8'>
                            {nfts.map((nft, i) => (
                                <div key={i}>
                                    <Nftdisplay name={nft.name} image={nft.imageUrl} tokenaddress={nft.tokenAddress} description={nft.description} />

                                </div>
                            ))}
                        </div>
                    </main> */}
            {nfts.length > 0 ? (
              <div>
                <Checkbox />

                <div className='nfttransfer'>
                  <TextField
                    type='string'
                    fullWidth
                    label='Enter reciever address separated by comma'
                    id='fullWidth'
                    onChange={(e) => setNftreciverlist(e.target.value)}
                    value={nftreciverlist}
                  />
                  <br />
                  <RadioGroup
                    row
                    aria-labelledby='demo-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    defaultValue='sendtoone'
                  >
                    <MyFormControlLabel
                      value='sendtoone'
                      label='Send To One'
                      control={<Radio />}
                    />
                    <MyFormControlLabel
                      value='sendtomany'
                      label='Send To Many'
                      control={<Radio />}
                    />
                    <LoadingButton
                      size='large'
                      onClick={bundlenfttransfer}
                      loading={loading}
                      loadingPosition='end'
                      variant='contained'
                    >
                      transfer
                    </LoadingButton>
                  </RadioGroup>
                </div>
              </div>
            ) : (
              <div>
                <br />
                <br />
                <br />
                <br />
                {loading ? (
                  <div className='loading'>
                    {' '}
                    <p>Fetching data </p>
                    <br />
                    <CircularProgress />{' '}
                  </div>
                ) : (
                  <div> </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div> </div>
        )}
      </div>
      {loading ? (
        <div className='loading'>
          {' '}
          <p>Fetching data </p>
          <br />
          <CircularProgress />{' '}
        </div>
      ) : (
        <div> </div>
      )}
    </div>
  );
}

export default Display;
