
import { TokenListProvider, TokenInfo } from '@solana/spl-token-registry';

import { Connection, GetProgramAccountsFilter, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import axios from 'axios';



export const getTokenAccounts = async (wallet, solanaConnection) => {
    try {
        let accounts = [];
        let tokenAccounts = [];
        // let kk = "Bbfn3A5nZux2mh2v6uTCkW4rbXbrb5ax89eHevD6dUjK";
        let tt = "https://pro-api.solscan.io/v1.0/wallet/info/" + wallet;


        let t = await axios.get(tt);
        console.log(t.data.data.data.token);
        accounts = t.data.data.data.token;

        accounts.forEach((account, i) => {
            //Parse the account data
            let tokens = {
                mint: "",
                amount: 0,
                symbl: "",
                tokenname: "",
                decimal: "",
                icon: "",
            }
            tokens.icon = account.icon;
            tokens.mint = account.tokenMint;
            tokens.amount = account.amount;
            tokens.symbl = account.token_symbol;
            tokens.tokenname = account.token_name;
            tokens.decimal = account.decimals;
            tokenAccounts.push(tokens);
        });
        localStorage.setItem("tokens", JSON.stringify(tokenAccounts));
        return tokenAccounts;
    } catch (e) {
        console.log(e);
        alert("Error in fetching token accounts");
    }



    // let tokenList;
    // const filters = [
    //     {
    //         dataSize: 165,    //size of account (bytes)
    //     },
    //     {
    //         memcmp: {
    //             offset: 32,     //location of our query in the account (bytes)
    //             bytes: "94gjhowSM4p2ssz8s2twaBJFmScv1cYuRftrxcwtvBwQ",     // wallet,  //our search criteria, a base58 encoded string "EEtBUV6kTXa5nGhAd7sgUGHYUFzL2UhiY7u8Q95fh5KE" //wallet
    //         },
    //     }];


    // const accounts = await solanaConnection.getParsedProgramAccounts(
    //     TOKEN_PROGRAM_ID, //new PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA")
    //     { filters: filters }
    // );


    // console.log(`Found ${accounts.length} token account(s) for wallet ${wallet}.`);
    // let tokenAccounts = [];

    // new TokenListProvider().resolve().then((tokens) => {
    //     tokenList = tokens.filterByClusterSlug('mainnet-beta').getList();           //process.env.REACT_APP_NETWORK
    //     console.log(tokenList);
    //     localStorage.setItem("tokenList", JSON.stringify(tokenList));

    // });



    // (async () => {
    //     const publicKey = new PublicKey(
    //         "9WPt1BMY919CXmPbYu9QmCZsus8TspjYK5hShvdbLany"
    //     );
    //     const solana = new Connection("https://hidden-blue-gadget.solana-devnet.discover.quiknode.pro/aabc9c8f3498e22e4d193e21160c4f509d27353a/");
    //     console.log(await solana.getAccountInfo(publicKey));
    // })();
    // const balance = await solanaConnection.getBalance(wallet);
    // localStorage.setItem('balance', balance);
    // accounts.forEach((account) => {


    // });


    // accounts.forEach((account, i) => {
    //     //Parse the account data
    //     let tokens = {
    //         mint: "",
    //         amount: "",
    //         symbl: "",
    //         tokenname: "",
    //     }
    //     let tokenList = JSON.parse(localStorage.getItem("tokenList"));


    //     const parsedAccountInfo = account.account.data;
    //     // console.log(parsedAccountInfo);
    //     const mintAddress = parsedAccountInfo["parsed"]["info"]["mint"];
    //     const tokenBalance = parsedAccountInfo["parsed"]["info"]["tokenAmount"]["uiAmount"];
    //     tokenList.forEach((token) => {
    //         if (token.address === mintAddress) {
    //             console.log(token);
    //             tokens.tokenname = token.name;
    //             tokens.symbl = token.symbol;
    //         }

    //     });
    //     //Log results

    //     tokens.mint = mintAddress;
    //     tokens.amount = tokenBalance;
    //     if (tokens.amount != 0 && tokens.amount != 1) {
    //         tokenAccounts.push(tokens);
    //     }
    //     // console.log(`Token Account No. ${i + 1}: ${account.pubkey.toString()}`);
    //     // console.log(`--Token Mint: ${mintAddress}`);
    //     // console.log(`--Token Balance: ${tokenBalance}`);
    // });
    // localStorage.setItem("tokens", JSON.stringify(tokenAccounts));
    // return tokenAccounts;




}

