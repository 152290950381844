import { clusterApiUrl, Connection } from '@solana/web3.js';

export const connectionString = process.env.REACT_APP_NETWORK;
export const COMMITMENT = "singleGossip";

// export const connection = new Connection(
//     clusterApiUrl(connectionString),
//     'confirmed'
// )

const rpcEndpoint = process.env.REACT_APP_RCP_END_POINT;              //                  'https://api.devnet.solana.com';
export const connection = new Connection(rpcEndpoint);
