import React, { Component } from 'react';
import axios from "axios";
import { useState } from 'react';
import { useEffect } from 'react';
import './Nftdisplay.css';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
function Nftdisplay({ name, description, price, owner, tokenaddress, image }) {
    // const [nftData, setNftData] = useState(null);


    // useEffect(() => {
    //     axios.get(nft)
    //         .then(res => {
    //             console.log(res);
    //             setNftData(res.data);
    //         })
    // }, []);


    return (
        <div style={{ padding: "30px" }}>

            <div>
                <img style={{ width: 200, height: 200 }} src={image} alt="nft" />
                <h5>mint: {tokenaddress}</h5>
                <h5>Name: {name}</h5>
                <p>description: {description}</p> </div>
        </div>
    );
}


export default Nftdisplay;

